import { useEffect, useState } from 'react';
import { useConfig } from './useContract';
import BigNumber from 'bignumber.js';

export default function useConfigContract() {
    const contract = useConfig();
    const [vestRatio, setVestRatio] = useState(0);

    async function getVestingRadio() {
        if (!contract) return;

        try {
            const res = await contract.vestingRatio();

            if (res) {
                setVestRatio(
                    new BigNumber(res?.toString()).shiftedBy(-4).toNumber(),
                );
            }
        } catch (e) {
            console.error('get vestRatio error');
            console.error(e);
        }
    }

    useEffect(() => {
        getVestingRadio();
    }, [contract]);

    return {
        vestRatio,
    };
}
