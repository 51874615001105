export default {
    mint: [
        {
            q: 'What is the relationship between enki and sequencer?',
            a: 'ENKI is a groundbreaking protocol designed to empower everyday investors, allowing them to seamlessly generate income through participation in the Metis Sequencer Node ecosystem. Traditionally, this process has been complex and limited to a select group due to a whitelist system. However, ENKI has secured a spot on this whitelist, positioning itself uniquely to open the doors for regular users to engage and earn rewards.',
        },
        {
            q: 'What is the eMetis?',
            a: 'This acts as a stablecoin, loosely pegged to Metis. It leverages Metis Sequencer Node staking and enhances the composability of the Metis ecosystem.',
        },
        {
            q: 'Is the exchange ratio between metis and metis fixed?',
            a: 'The process of converting Metis to eMetis maintains a 1:1 ratio and is straightforward. ',
        },
        {
            q: 'How to exchange eMetis to Metis?',
            a: `The withdrawal process is currently disabled until an effective solution to the restrictions from the Metis Sequencer node locking pool is discovered.
            The Metis Sequencer node locking pool imposes two primary restrictions for unlocking Metis from a node:
            No partial withdrawal is allowed, meaning that a node with a large amount of locked Metis can only unlock the total amount, leading to node termination.
            A 21-day locking period is required after an unlocking request for a sequencer node.	
            Additionally, as the ENKI protocol is built on Layer 2 (Metis Andromeda), there is also a 7-day challenge period for potential sequencer node recreation following withdrawal.
            In summary, a sustainable and efficient solution needs to be devised to address withdrawal challenges within the current system architecture. Meanwhile, users can still convert their eMetis to Metis on the secondary market (DEXes, etc.). We are committed to enhancing the secondary market liquidity of eMetis/Metis and realizing the withdrawal process as swiftly as possible.
            `,
        },
        {
            q: 'Is there a cap on the supply of eMetis?',
            a: 'It depends on the supply of Metis.',
        },
        {
            q: 'Is there any limit on mint eMetis per person?',
            a: 'Not yet.But we are against malicious investors.',
        },
    ],
    stake: [
        {
            q: 'How to stake your eMetis and earn eMetis yield？',
            a: 'This is a version of eMetis that accrues staking yield. The majority of the profit generated from Metis sequencer nodes is distributed to seMetis holders. By staking eMetis for seMetis, users can earn staking yields, redeemable when converting seMetis back to eMetis.',
        },
        {
            q: 'How long does it take for me to see benefits after staking eMetis?',
            a: '7-day period: The Metis Sequencer node locking pool is located on Layer 1 (Ethereum). It takes a 7-day challenge period to transfer deposited Metis from the ENKI protocol on Layer 2 (Metis Andromeda) via the Metis bridge. This 7-day period also applies to the distribution of earned Metis from the sequencer locking pool.',
        },
        {
            q: 'What is the seMetis?',
            a: 'Semetis is the user’s certificate for staking eMetis, which will still be stored in the user’s wallet.',
        },
        {
            q: 'What is the reward APL for staking eMetis?',
            a: 'APL is not fixed, which depends on the sequencer’s investment reward.',
        },
        {
            q: 'How to receive eMetis rewards？',
            a: 'When redeeming from seMetis yield vault, 70% of the accrued eMetis is directly sent to the redeemer. The other 30% is sent to a vesting contract. To access this, users must stake an equivalent amount of ENKI for 365 days, promoting long-term commitment to the ENKI protocol.',
        },
        {
            q: 'How to get ENKI tokens？',
            a: `The forecasted max supply is 10 million ENKI tokens. The increase in circulating supply will vary depending on the number of tokens that get allocated via different methods(liquidity mining, etc.), and the amount of tokens used for marketing / partnerships.
            Minting beyond the max supply of 10 million will be controlled by a 28 day timelock. This option will only be used if more products are launched and long-term liquidity mining is required, a governance vote will be conducted before any changes.
            Initial allocation plan is the following:
            1 million ENKI reserved for marketing, partnerships and community contributors.
            1 million ENKI reserved for pairing with Metis for liquidity on DEX.
            8 million ENKI reserved for vesting from community incentives via different methods. 
            `,
        },
        {
            q: 'How long will it take for the remaining 30% of the rewards to be released?',
            a: '365 days.If you unstake ENKI, which will interrupt the release of rewards.',
        },
    ],
    all: [
        {
            q: 'What is ENKI?',
            a: 'ENKI is an innovative protocol designed to simplify and democratize participation in the Metis Sequencer Node ecosystem. It empowers regular investors to earn rewards by staking Metis Sequencer Nodes and enhances the composability of the Metis ecosystem.',
        },
        {
            q: 'How does ENKI work?',
            a: 'ENKI operates through three primary components: eMetis (a stablecoin), seMetis (which accrues staking yield), and the ENKI Metis Minter (for Metis to eMetis exchange). Users can stake eMetis to earn seMetis and participate in the Metis Sequencer Node ecosystem, simplifying the staking process and enhancing rewards.',
        },
        {
            q: 'Why ENKI?',
            a: 'ENKI addresses the complexities and limitations of traditional Metis Sequencer Node staking, offering a user-friendly platform for earning rewards. It opens opportunities for regular users to participate, regardless of their Metis holdings, and contributes to the growth and composability of the Metis ecosystem.',
        },
    ],
};
