import { ChainId } from 'constant';
import { computed, makeAutoObservable } from 'mobx';
import storage from 'utils/storage';
import user from './user';

interface TokensPrice {
    [key: string]: {
        price: number;
    };
}

const tokenPrice = storage.get('enki_token_price_withchain');
export class TokenInfo {
    tokenPriceWithChain: {
        [ChainId.mainnet]: TokensPrice;
        [ChainId.testnet]: TokensPrice;
        [ChainId.testnetSepolia]: TokensPrice;
    } = tokenPrice || {
        [ChainId.mainnet]: {},
        [ChainId.testnet]: {},
        [ChainId.testnetSepolia]: {},
    };
    fetchTokenList: string[] = [];

    constructor() {
        makeAutoObservable<this>(this);
    }

    @computed get tokenPrice() {
        return this.tokenPriceWithChain[user.chainId];
    }

    setTokensPrice(tokensPrice: TokensPrice) {
        this.tokenPriceWithChain[user.chainId] = {
            ...this.tokenPrice,
            ...tokensPrice,
        };
        storage.set('enki_token_price_withchain', this.tokenPriceWithChain);
    }

    addFetchTokens(tokens: string[]) {
        this.fetchTokenList = [
            ...Array.from(new Set(this.fetchTokenList.concat(tokens))),
        ];
    }
}

export default new TokenInfo();
