import React from 'react';
import Button from '../Button';
// import style from './index.module.scss';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Token } from '../../constant';
import { plus } from 'common-helpers/img';
import { useAddToken } from 'hooks/useAddToken';

const StyledButton = styled(Button)`
    color: #64d9cd !important;
    box-sizing: border-box;
    padding: 0 !important;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-size: 14px !important;
    background: transparent;
    border: none;
    height: auto !important;
    /* &:hover {
        background-color: #122537;
    } */
    .loadingIcon {
        color: #122537;
        font-size: 20px;
    }
`;

interface Props {
    token: Token;
    style?: React.CSSProperties;
}

function AddToken({ token, style }: Props) {
    const [canAdd, addToken] = useAddToken(token);

    return (
        <StyledButton
            onClick={addToken}
            type="transparent"
            showLoading
            loadingProps={{
                // classname: style.loadingIcon,
                size: 24,
            }}
            style={style}
        >
            <img src={plus} alt="" />
        </StyledButton>
    );
}

export default observer(AddToken);
