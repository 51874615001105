import React, { useEffect, useMemo, useState } from 'react';

import Row, { RowBetween } from 'components/Row';

import Button from 'components/Button';
import BigNumber from 'bignumber.js';

import { Token } from '../../constant';

import styled from 'styled-components';
import { TYPE } from 'theme';
import { getTokenImg } from 'utils';
import { metisSymbol } from 'common-helpers/img';
import useTokenBalance from 'hooks/useTokenBalance';
import user from 'stores/user';
import { observer } from 'mobx-react-lite';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    border-radius: 4px;
    background: transparent;
    width: 100%;
    box-sizing: border-box;
    gap: 20px;

    .tokenWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        img {
            width: 48px;
            height: 48px;
            ${({ theme }) => theme.mediaWidth.upToSmall`
            width: 30px;
            height: 30px;
            `};
        }
    }

    .input {
        background: transparent;
        flex-grow: 1;
        width: inherit;
        height: 30px;
        margin-left: 20px;
        outline: none;
        border: none;
        color: ${({ theme }) => theme.c.t01};
        font-size: 36px;
        font-weight: bold;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        ${({ theme }) => theme.mediaWidth.upToSmall`
         font-size: 30px;
        `};
        &::placeholder {
            color: ${({ theme }) => theme.c.t02};
            ${({ theme }) => theme.mediaWidth.upToSmall`
            font-size: 30px;
            `};
        }
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
     .line {
          flex-wrap: wrap;
      }

      .input {
          min-width: 20%;
          flex-shrink: 1;
          height: 100%;
      }

    `}
`;

const MaxButton = styled(Button)`
    width: 100px;
    height: 48px !important;
    background: #07ddd0;

    font-size: 18px !important;
    font-weight: 600;
    border-radius: 15px !important;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;

    ${({ theme }) => theme.mediaWidth.upToSmall`
          padding: 10px 15px !important;
    `};
`;

const InputRow = styled(Row)`
    width: 100%;
    margin-top: 24px;

    height: 100px;
    align-items: center;
    box-sizing: border-box;
    font-size: 18px !important;
    font-weight: bold !important;
    padding: 24px;
    border-radius: 24px;
    background: ${({ theme }) => theme.c.bg04};
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 75px;
    padding: 8px 16px;
    margin-top: 8px;
    `}/* border-bottom: 1px solid rgba(7, 221, 208, 0.2); */
`;

const PartButton = styled(Button)`
    height: 48px !important;
    color: #000 !important ;
    font-size: 18px !important;
    font-weight: 600;
    border-radius: 15px !important;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;
    margin-right: 10px;
    border: 1px solid #e8dbd8 !important;

    ${({ theme }) => theme.mediaWidth.upToSmall`
          padding: 10px 15px !important;
    `};
`;

interface Props {
    value: BigNumber;
    onChange: (value: BigNumber) => void;
    disabled?: boolean;
    onInputValid?: (value: boolean) => void;
    token: Token;
    tokenImage?: string;
    inputPercent?: number[];
    bindUpdateMethod?(method: () => void): void;
    show?: boolean;
}

function TokenInput({
    value,
    onChange,
    disabled,
    onInputValid,
    token,
    tokenImage,
    inputPercent,
    show = true,
}: Props) {
    // const balance  = useBalance(tokenAddress)
    const { balance, getBalance } = useTokenBalance(token.address);
    const [input, setInput] = useState('');

    useEffect(() => {
        if (!new BigNumber(input || 0).isEqualTo(value)) {
            setInput(value.toNumber() ? value.toFixed() : '');
        }
    }, [value, input]);

    const errorMessage = useMemo(() => {
        if (value.isGreaterThan(balance)) {
            return 'Insufficient balance';
        } else {
            return '';
        }
    }, [value, balance]);

    useEffect(() => {
        if (onInputValid) {
            onInputValid(!errorMessage);
        }
    }, [errorMessage, onInputValid]);

    useEffect(() => {
        if (value.eq(0)) {
            getBalance();
        }
    }, [value]);

    const hasBalance = useMemo(() => {
        return balance.isGreaterThan(0);
    }, [balance]);

    if (!show) return null;

    return (
        <>
            <Wrapper>
                <InputRow align="between">
                    <div className="tokenWrapper">
                        <img className="token" src={tokenImage || ''} />
                        <TYPE.main fontSize={18} fontWeight={600}>
                            {token.symbol}
                        </TYPE.main>
                    </div>
                    <input
                        type="number"
                        placeholder="0.00"
                        className={'input'}
                        value={input}
                        onChange={(e) => {
                            if (Number(e.currentTarget.value) < 0) {
                                setInput('0');
                                onChange(new BigNumber(0));
                            } else {
                                setInput(e.currentTarget.value);
                                onChange(
                                    new BigNumber(
                                        `${e.currentTarget.value}` || 0,
                                    ),
                                );
                            }
                        }}
                        disabled={disabled}
                    />
                    {!disabled &&
                        hasBalance &&
                        inputPercent?.map((item, index) => (
                            <PartButton
                                key={`partbutotn_${index}`}
                                type="outline"
                                onClick={() => {
                                    onChange(
                                        balance
                                            .multipliedBy(item)
                                            .dividedBy(100),
                                    );
                                }}
                                loadingProps={{
                                    classname: 'loading',
                                    size: 16,
                                }}
                                showLoading
                            >
                                {item}%
                            </PartButton>
                        ))}

                    {!disabled && hasBalance && (
                        <MaxButton
                            onClick={() => {
                                onChange(balance);
                            }}
                            loadingProps={{
                                classname: 'loading',
                                size: 16,
                            }}
                            showLoading
                        >
                            Max
                        </MaxButton>
                    )}
                </InputRow>
                {user.connected && (
                    <Row justify="flex-end">
                        <TYPE.main fontSize={14}>
                            Your {token.symbol} Balance: {balance.toFixed(3)}{' '}
                            {token.symbol}
                        </TYPE.main>
                    </Row>
                )}
                {!disabled && (
                    <Row justify="flex-end">
                        <TYPE.main fontSize={14} color="red">
                            {errorMessage}
                        </TYPE.main>
                    </Row>
                )}
                {/* {!hideAddToken && (
                    <Row align="between">
                        <span></span>
                        <AddToken token={token} />
                    </Row>
                )} */}
            </Wrapper>
        </>
    );
}

export default observer(TokenInput);
