import React, { useState } from 'react';
import styled from 'styled-components';
import ReactECharts from 'echarts-for-react';

const Wrapper = styled.div`
    width: 100%;
    min-width: 600px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    min-width: unset;
    `};
`;

const SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
`;

const SwtichItem = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #0e0905;
    opacity: 0.5;
    cursor: pointer;

    &.onSelected {
        opacity: 1;
        font-weight: bold;
    }
`;
export default function LineChart() {
    const [options] = useState({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM'],
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: 'line',
                showSymbol: false,
                areaStyle: {
                    color: '#FDD436',
                },
                lineStyle: {
                    color: '#FDD436',
                },
                itemStyle: {
                    color: '#FDD436',
                },
            },
        ],
    });
    return (
        <Wrapper>
            <SwitchWrapper>
                <SwtichItem className="onSelected">15M</SwtichItem>
                <SwtichItem>1H</SwtichItem>
                <SwtichItem>1D</SwtichItem>
                <SwtichItem>1W</SwtichItem>
                <SwtichItem>3M</SwtichItem>
                <SwtichItem>6M</SwtichItem>
                <SwtichItem>1Y</SwtichItem>
            </SwitchWrapper>
            <ReactECharts
                option={options}
                notMerge={true}
                lazyUpdate={true}
                style={{ width: '100%', height: 400 }}
                // onChartReady={this.onChartReadyCallback}
                // onEvents={EventsDict}
                // opts={}
            />
        </Wrapper>
    );
}
