import { gql } from '@apollo/client';
import { getApolloClient } from './index';
import { Client } from './config';

interface GraphToken {
    holders: number;
}

export function token(key: string): Promise<GraphToken | undefined> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<any>({
                query: gql`
                    query token($key: String!) {
                        token(key: $key) {
                            holders
                        }
                    }
                `,
                variables: {
                    key,
                },
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                const { data } = res;
                resolve(data?.token);
            })
            .catch((e: any) => {
                resolve(undefined);
                console.error(e);
            });
    });
}
