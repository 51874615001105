import React from 'react';
import styled from 'styled-components';
import MCheckBox from '@mui/material/Checkbox';
import { TYPE } from 'theme';
const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
    .MuiButtonBase-root {
        padding: 0;
        &.Mui-checked {
            .MuiSvgIcon-root {
                color: #ffc200 !important;
            }
        }
    }

    .MuiSvgIcon-root {
        color: #e8dbd8;
    }
`;

interface Props {
    checked: boolean;
    onCheck(checked: boolean): void;
    text?: string;
}

export default function Checkbox({ checked, onCheck, text }: Props) {
    return (
        <Wrapper>
            <MCheckBox
                checked={checked}
                onChange={(e: any) => {
                    onCheck(e.target.checked);
                }}
            />
            <TYPE.main shrink fontSize={14}>
                {text}
            </TYPE.main>
        </Wrapper>
    );
}
