import LineChart from 'components/LineChart';
import Row, { RowBetween } from 'components/Row';
import React from 'react';
import styled from 'styled-components';
import { Link, TYPE } from 'theme';

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 48px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    gap: 20px;
    `};
`;

const InfoContainer = styled.div`
    background: #ffffff;
    border-radius: 24px;
    box-sizing: border-box;
    padding: 32px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 10px;
    `};
    .token {
        width: 32px;
        height: 32px;
    }
`;

const InfosWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    gap: 48px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 20px;
    flex-direction: column-reverse;
    `};
`;

const InfoItemBlockWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    .item {
        width: 252px;
        height: 120px;
        border-radius: 6px;
        border: 1px solid #e8dbd8;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
    }
`;

const InfoItem = styled.div`
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    flex-grow: 1;
`;

const ChartWrapper = styled.div`
    width: 550px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const ProgressBar = styled.div`
    position: relative;
    width: 528px;

    height: 48px;
    background: #fff6d7;
    border-radius: 4px;
    z-index: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-right: 8px;
`;

const Progress = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    background: #fdd436;
    border-radius: 4px;
    box-sizing: border-box;
    color: #000;
    display: flex;
    font-size: 14px;
    align-items: center;
    padding-left: 8px;
    justify-content: flex-start;
    z-index: 1;
`;

const FullBorderItem = styled.div`
    width: 100%;
    height: 120px;
    border-radius: 6px;
    border: 1px solid #e8dbd8;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    padding: 20px;
    box-sizing: border-box;
`;

export default function Balance() {
    return (
        <Wrapper>
            <InfoContainer>
                <InfosWrapper>
                    <InfoItem>
                        <TYPE.main fontSize={20} fontWeight={600}>
                            Balance
                        </TYPE.main>
                        <Row>
                            <TYPE.main fontSize={16}>
                                eMetis/Metis Reserves
                            </TYPE.main>
                        </Row>
                        <Row>
                            <ProgressBar>
                                <Progress>20%</Progress>
                                <Row justify="flex-end">
                                    <TYPE.main>80%</TYPE.main>
                                </Row>
                            </ProgressBar>
                        </Row>
                        <RowBetween>
                            <TYPE.main fontSize={14}>10000 eMetis</TYPE.main>
                            <TYPE.main fontSize={14}>3000 Metis</TYPE.main>
                        </RowBetween>
                        <Row>
                            <TYPE.main fontSize={16} fontWeight={600}>
                                eMetis/Metis APR
                            </TYPE.main>
                        </Row>
                        <FullBorderItem>
                            <TYPE.main
                                fontSize={20}
                                fontWeight={600}
                                mobileFz={14}
                            >
                                20.01%
                            </TYPE.main>
                            <TYPE.main fontSize={16} mobileFz={14}>
                                APR based off of staking LP tokens in
                                xxxxx.Trading fee APY based off last rolling 24
                                hours.
                            </TYPE.main>
                        </FullBorderItem>
                        <Row>
                            <TYPE.main
                                fontSize={16}
                                fontWeight={600}
                                mobileFz={14}
                            >
                                Links
                            </TYPE.main>
                        </Row>
                        <Row>
                            <Link href="#">
                                <TYPE.main fontSize={16} mobileFz={14}>
                                    eMetis/Metis pool on Netswap
                                </TYPE.main>
                            </Link>
                        </Row>
                    </InfoItem>
                    <InfoItem style={{ flexShrink: 0 }}>
                        <ChartWrapper>
                            <LineChart />
                        </ChartWrapper>
                    </InfoItem>
                </InfosWrapper>
            </InfoContainer>
        </Wrapper>
    );
}
