import React from 'react';
import styled from 'styled-components';

import {
    MRT_ColumnDef,
    MRT_TableOptions,
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

const Wrapper = styled.div`
    width: 100%;
    background-color: transparent;

    .MuiPaper-root {
        background-color: transparent;
        box-shadow: none;
        > .MuiBox-root {
            position: relative;
            box-shadow: none;
            background-color: transparent;
            .css-qpxlqp {
                position: relative;
                margin: 0 auto;
            }

            .MuiButtonBase-root {
                &.Mui-selected {
                    color: #000;
                    background-color: transparent;
                }
                color: #bfbdb8;
                border-radius: 6px;
                border: 1px solid rgba(0, 0, 0, 0.08);
                right: 50%;
                transform: translateX(50%);
            }

            .MuiPaginationItem-firstLast {
                color: #000;
            }
        }
    }

    .MuiTableRow-root {
        background-color: transparent;

        &.MuiTableRow-head {
            box-shadow: none;
        }
    }
    .MuiTableRow-root {
        background-color: transparent !important;
    }

    .MuiTableCell-root {
        border-bottom: 0;
        height: 80px;
    }
`;

interface Props extends MRT_TableOptions<any> {
    isLoading?: boolean;
}
export default function Table(props: Props) {
    const table = useMaterialReactTable({
        ...props,
        initialState: {
            pagination: {
                pageSize: 10,
                pageIndex: 0,
            },
            showLoadingOverlay: false,
        },
        state: {
            isLoading: props.isLoading,
        },
        muiPaginationProps: {
            color: 'primary',
            shape: 'rounded',
            showRowsPerPage: false,
            variant: 'outlined',
        },
        muiSkeletonProps: {
            animation: 'pulse',
            height: 28,
        },
    });
    return (
        <Wrapper>
            <MaterialReactTable table={table} />
        </Wrapper>
    );
}
