// eslint-disable-next-line no-use-before-define
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { getApolloClient } from './gql';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemedGlobalStyle } from 'theme';
import ThemeProvider from 'theme/ThemeProvider';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { createConfig, http, WagmiProvider } from 'wagmi';

import { Client } from 'gql/config';
import { BigNumber } from 'bignumber.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { metisSepolia } from 'common-helpers/metisSepoliachain';
import { walletConnect, injected, coinbaseWallet } from 'wagmi/connectors';
import { createWeb3Modal } from '@web3modal/wagmi';
BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN });

const queryClient = new QueryClient();
const projectId = 'b5605b05126c8a25b9b8bddfa5379db1';

const metadata = {
    name: 'ENKI',
    description: 'ENKI',
    url: window.location.origin, // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const config = createConfig({
    chains: [metisSepolia],
    transports: {
        [metisSepolia.id]: http(),
    },
    connectors: [
        walletConnect({ projectId, metadata, showQrModal: false }),
        injected({ shimDisconnect: true }),
        coinbaseWallet({
            appName: metadata.name,
            appLogoUrl: metadata.icons[0],
        }),
    ],
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

const client = getApolloClient(Client.graph);
createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

root.render(
    <Suspense fallback={<></>}>
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <ApolloProvider client={client as any}>
                    <StyledEngineProvider injectFirst>
                        <BrowserRouter>
                            <ThemeProvider>
                                <ThemedGlobalStyle />
                                <App />
                            </ThemeProvider>
                        </BrowserRouter>
                    </StyledEngineProvider>
                </ApolloProvider>
            </QueryClientProvider>
        </WagmiProvider>
    </Suspense>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
