import { Contract } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import user from 'stores/user';
import { useConstantToken } from './useConstantToken';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { RPC } from 'constant';

function decodeErrorString(error: any) {
    let errorMessage = '';
    error = new Error(error).message;
    if (error) {
        const reg = new RegExp('Error: Internal JSON-RPC error.');
        if (reg.test(error)) {
            const message = error.replace(reg, '');

            const errorData = JSON.parse(message);

            if (
                errorData &&
                typeof errorData === 'object' &&
                errorData.message
            ) {
                errorMessage = errorData.message;
            }
        } else {
            errorMessage = error;
        }
    }
    return errorMessage;
}

export function useGasEstimate() {
    const { address } = user;

    function gasEstimate(promise: any): Promise<{
        success: boolean;
        errorMessage?: string;
    }> {
        // const web3 = getWeb3()
        return new Promise(async (resolve, reject) => {
            // let gasPrice = 1000000000
            // if (web3) {
            //   gasPrice = await web3.eth.getGasPrice()
            // }
            if (promise) {
                promise.estimateGas(
                    {
                        from: address,
                    },
                    function (error: any, gasAmount: any) {
                        if (error) {
                            const errorMessage = decodeErrorString(error);
                            return resolve({
                                success: false,
                                errorMessage,
                            });
                        } else {
                            resolve({
                                success: true,
                            });
                        }
                    },
                );
            }
        });
    }

    return gasEstimate;
}

export function useEtherGasEstimate() {
    async function gasEstimate(
        contract: Contract,
        method: string,
        params: any,
    ): Promise<{
        success: boolean;
        errorMessage?: string;
    }> {
        try {
            // let gasPrice = 1000000000
            // if (library?.provider) {
            //   gasPrice = await (library.provider as any).getGasPrice()
            //   console.log(gasPrice)
            // }
            const estimation = await contract.estimateGas[method](...params);
            console.log('contract', contract);
            console.log('method', method);
            console.log('params', params);
            console.log('estimation', estimation.toString());
            return {
                success: true,
            };
        } catch (e) {
            // const errorMessage = decodeErrorString(e)
            const errorMessage =
                (e as any)?.data?.message || (e as any)?.message;
            return {
                success: false,
                errorMessage,
            };
        }
    }

    return gasEstimate;
}

export function useGetEstimatedGas({
    contract,
    method,
    params,
    show = false,
    fetch = false,
}: {
    contract: Contract | null;
    method: string;
    params: any;
    show?: boolean;
    fetch?: boolean;
}) {
    const [estimatedGas, setEstimatedGas] = useState('0');
    const { metis } = useConstantToken();
    const web3 = useMemo(() => {
        return new Web3(new Web3.providers.HttpProvider(RPC[user.chainId]));
    }, [user.chainId]);
    async function getGas() {
        try {
            if (!contract || !user.connected) return;
            const estimation = await contract.estimateGas[method](...params);
            const gasPrice = await web3?.eth.getGasPrice();
            if (estimation) {
                setEstimatedGas(
                    new BigNumber(estimation?.toString())
                        .shiftedBy(-metis.decimals)
                        .multipliedBy(gasPrice || 1)
                        .toFixed(),
                );
            }
        } catch (e) {
            console.error('get gas fee error');
            console.error(contract, method, params);
            console.error(e);
        }
    }

    useEffect(() => {
        if (!fetch) return;
        getGas();
    }, [contract, method, params, user.connected, fetch, web3]);
    return show ? estimatedGas : '0';
}
