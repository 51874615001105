import BigNumber from 'bignumber.js';
import {
    Profit,
    getFiveMonthProfit,
    getGlobalProfit,
    getProfit,
    getRewardsHistory,
} from 'gql/profits';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import user from 'stores/user';
import { useConstantToken } from './useConstantToken';

export function useGetDailyProfit(startTime?: number) {
    const { eMetis } = useConstantToken();
    const [isLoading, setIsLoading] = useState(false);
    const [profits, setProfits] = useState<
        {
            date: string;
            dailyReward: string;
        }[]
    >([]);

    async function getHistory() {
        if (!user.address) return;
        setIsLoading(true);
        const end = Math.floor(Date.now() / 1000);

        const res = await getRewardsHistory({
            start: startTime,
            end,
            user: user.address,
        });

        if (res) {
            setProfits(
                res
                    .sort((a, b) => b.start - a.start)
                    .map((item) => {
                        return {
                            date: moment(item.start * 1000).format('DD/MM/YY '),
                            dailyReward: new BigNumber(item.value)
                                .shiftedBy(-eMetis.decimals)
                                .toFixed(4),
                            apr: `${new BigNumber(item.apr)
                                .shiftedBy(-eMetis.decimals)
                                .toFixed(4)}%`,
                        };
                    }),
            );
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getHistory();
    }, [user.address, startTime, eMetis]);

    return {
        profits,
        isLoading,
    };
}

export function useGetMonthChartData(startTime?: number) {
    const { eMetis } = useConstantToken();
    const [fiveMonthProfits, setFiveMonthProfits] = useState<
        (Profit | undefined)[]
    >([]);
    const [totalProfit, setTotalProfit] = useState(new BigNumber(0));
    async function getFiveMonthProfitFunc() {
        if (!user.address) return;
        const res = await getFiveMonthProfit(user.address);
        setFiveMonthProfits(res);
    }

    async function getTotalProfit() {
        if (!user.address || !startTime) return;
        const res = await getProfit(
            startTime,
            Math.floor(Date.now() / 1000),
            user.address,
        );

        if (res?.value) {
            setTotalProfit(
                new BigNumber(res.value).shiftedBy(-eMetis.decimals),
            );
        }
    }

    useEffect(() => {
        getFiveMonthProfitFunc();
    }, [user.address]);

    useEffect(() => {
        getTotalProfit();
    }, [user.address, startTime]);

    const lastFiveMonthProfit = useMemo(() => {
        return fiveMonthProfits.map((item) =>
            new BigNumber((item as any)?.value || 0)
                .shiftedBy(-eMetis.decimals)
                .toNumber(),
        );
    }, [fiveMonthProfits, eMetis]);

    const estimateProfit = useMemo(() => {
        const lastMonth = fiveMonthProfits[fiveMonthProfits.length - 1];
        return [
            null,
            null,
            null,
            null,
            lastMonth
                ? new BigNumber(lastMonth?.value)
                      .shiftedBy(-eMetis.decimals)
                      .multipliedBy(
                          new BigNumber(lastMonth?.apr).shiftedBy(
                              -eMetis.decimals,
                          ),
                      )
                      .toFixed()
                : null,
        ];
    }, [fiveMonthProfits, eMetis]);

    const monthlyProfit = useMemo(() => {
        if (!startTime) return new BigNumber(0);
        const diffMonth =
            moment(Date.now()).diff(moment(startTime * 1000), 'month') + 1;
        return totalProfit.dividedBy(diffMonth);
    }, [totalProfit, startTime]);
    return {
        monthlyProfit: monthlyProfit,
        totalProfit: totalProfit,
        estimateProfit: estimateProfit,
        actualProfit: lastFiveMonthProfit,
    };
}

export function useGetGlobalProfit() {
    const { eMetis } = useConstantToken();
    const [isLoading, setIsLoading] = useState(false);
    const [profit, setProfit] = useState<Profit | undefined>();

    async function getGlobalProfitFunc() {
        setIsLoading(true);

        const res = await getGlobalProfit();
        setProfit(res);

        setIsLoading(false);
    }

    useEffect(() => {
        getGlobalProfitFunc();
    }, []);

    const totalProfit = useMemo(() => {
        return new BigNumber(profit?.value || 0)
            .shiftedBy(-eMetis.decimals)
            .toNumber();
    }, [profit, eMetis]);

    const stakingApr = useMemo(() => {
        return new BigNumber(profit?.apr || 0)
            .shiftedBy(-eMetis.decimals)
            .toNumber();
    }, [profit, eMetis]);

    return {
        totalProfit,
        stakingApr,
        isLoading,
    };
}
