import * as React from 'react';
import { usePublicClient, useAccount, useWalletClient } from 'wagmi';
import { providers } from 'ethers';
import { type HttpTransport } from 'viem';
import { DefaultChainId, NETWORK_CONFIG, RPC, SupportChainIDs } from 'constant';
import Web3 from 'web3';

export function publicClientToProvider(publicClient: any, walletClient?: any) {
    const network = {
        chainId: publicClient?.chain.id || DefaultChainId,
        name: publicClient?.chain.name || NETWORK_CONFIG[DefaultChainId].name,
        ensAddress: publicClient?.chain.contracts?.ensRegistry?.address,
    };

    const currentProviderChainId =
        Number(
            (window as any).web3 &&
                (window as any).web3.currentProvider?.chainId,
        ) || 1;

    if (walletClient) {
        const web3: any = new Web3(walletClient as any);
        web3.eth.extend({
            methods: [
                {
                    name: 'chainId',
                    call: 'eth_chainId',
                    outputFormatter: web3.utils.hexToNumber,
                },
            ],
        });

        if (web3?.currentProvider) {
            const provider = new providers.Web3Provider(web3?.currentProvider);
            return provider;
        }
    }

    if (
        SupportChainIDs.indexOf(currentProviderChainId) >= 0 &&
        (window as any).web3.currentProvider?.selectedAddress
    ) {
        return new providers.Web3Provider((window as any).web3.currentProvider);
    }
    if (publicClient?.transport.type === 'fallback') {
        return new providers.FallbackProvider(
            (
                publicClient?.transport
                    .transports as ReturnType<HttpTransport>[]
            ).map(
                ({ value }) =>
                    new providers.JsonRpcProvider(value?.url, network),
            ),
        );
    }
    return new providers.JsonRpcProvider(
        publicClient?.transport.url || RPC[DefaultChainId],
        network,
    );
}

/** Hook to convert a viem Public Client to an ethers.js Provider. */
export function useEthersProvider({
    chainId,
    account,
}: { chainId?: number; account?: string } = {}) {
    const publicClient = usePublicClient({ chainId });
    const { address } = useAccount();
    const { data: walletClient } = useWalletClient();
    return React.useMemo(
        () =>
            publicClientToProvider(
                publicClient,
                address ? walletClient : undefined,
            ),
        [publicClient, account, walletClient, address],
    );
}
