import { getApolloClient } from 'gql';
import { Client } from './config';
import { gql } from '@apollo/client';
import { ChainId } from 'constant';

export interface SequencerInfo {
    apr: string;
    l1Staked: string;
    syncingToL1: string;
    totalRewards: string;
}

export function getSequencerInfo(
    chain?: ChainId,
): Promise<SequencerInfo | undefined> {
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph, chain)
            .query<{
                sequencerInfo: SequencerInfo;
            }>({
                query: gql`
                    query sequencerInfo {
                        sequencerInfo {
                            apr
                            l1Staked
                            syncingToL1
                            totalRewards
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                const { data } = res;
                if (data?.sequencerInfo) {
                    resolve(data?.sequencerInfo);
                    resolve(undefined);
                }
            })
            .catch((e: any) => {
                resolve(undefined);
            });
    });
}
