import React from 'react';
import styled from 'styled-components';
import Modal, { Props } from '../Modal/modal';
import { TYPE } from 'theme';
import { RowBetween } from 'components/Row';
import { AutoColumn } from 'components/Column';
import { Token } from '../../constant';
import { isMobile } from 'react-device-detect';

interface TranscationProps extends Props {
    title: string;
    amountDesc: string;
    amount: string;
    token: Token;
}

const Wrapper = styled(Modal)`
    .modalContent {
        background-color: #fff8e4;
        width: 680px;
        padding: 40px;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        padding: 20px;
        `};

        .closeIcon {
            background: transparent;
        }
    }
`;
export default function TranscationModal({
    title,
    amountDesc,
    amount,
    token,
    children,
    ...props
}: TranscationProps) {
    return (
        <Wrapper {...props}>
            <AutoColumn gap={isMobile ? '10px' : '20px'}>
                <TYPE.main fontSize={20} fontWeight={600}>
                    {title}
                </TYPE.main>
                {!!amountDesc && (
                    <RowBetween flexWrap="wrap">
                        <TYPE.main fontSize={14}>{amountDesc}</TYPE.main>
                        <TYPE.main fontSize={14} fontWeight={600}>
                            {amount} {token.symbol}
                        </TYPE.main>
                    </RowBetween>
                )}
                {children}
            </AutoColumn>
        </Wrapper>
    );
}
