import { getCurrentChainId } from 'gql';
import { ChainId, DefaultChainId, RPC } from '../constant';
import { makeAutoObservable } from 'mobx';
import storage from 'utils/storage';
import Web3 from 'web3';

export class User {
    isOpenConnectModal: boolean = false;
    isDark: boolean = !!storage.get('bridge-theme');
    web3: any;
    address: string = '';
    chainId: ChainId = getCurrentChainId();
    connected: boolean = false;
    constructor() {
        makeAutoObservable<this>(this);
    }

    toggleConnectModal = () => {
        this.isOpenConnectModal = !this.isOpenConnectModal;
    };

    toggleTheme = () => {
        this.isDark = !this.isDark;
    };

    setWeb3 = (web3: any) => {
        this.web3 = web3;
    };

    setAddress = (address: string) => {
        this.address = address;
    };

    setConnected = (connected: boolean) => {
        this.connected = connected;
    };
    setChainId = (chainId: ChainId) => {
        this.chainId = chainId;
    };

    clearLoginInfo = () => {
        this.web3 = new Web3(
            new Web3.providers.HttpProvider(RPC[DefaultChainId]),
        );
        this.chainId = DefaultChainId;
        this.address = '';
        this.connected = false;
    };
}

export default new User();
