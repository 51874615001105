import React from 'react';
import styled from 'styled-components';
import MetisLogo from 'assets/images/token/metis.svg';

import EnkiLogo from 'assets/images/token/enki.svg';
import { twitter } from 'common-helpers/img';

const Wrapper = styled.div`
    position: fixed;
    top: 60%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    ${({ theme }) => theme.mediaWidth.upToSmall`
  top: 47%;
  `};
`;

const Placeholdertext = styled.div`
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #eac578;
    width: 320px;
    line-height: 21px;
    text-align: center;
    vertical-align: middle;
    ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 90%;
  box-sizing: border-box;
  padding: 0 10px;
  `};
    line-height: 1.8;
`;

const JoinText = styled.div`
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #eac578;
    line-height: 21px;
    margin-top: 157px;
    opacity: 0.5;
`;

const MediaWrapper = styled.div`
    margin-top: 24px;
`;

const MediaImg = styled.img`
    width: 28px;
    height: 28px;
    margin: 0 12px;
`;

const TokenImg = styled.img`
    width: 18px;
    height: 18px;
    vertical-align: sub;
`;

export default function ComingSoon() {
    return (
        <Wrapper>
            <Placeholdertext>
                Seamlessly Stake&nbsp;
                <TokenImg src={MetisLogo} />
                &nbsp;Metis, Earn Rewards, and Embrace DeFi with&nbsp;
                <TokenImg src={EnkiLogo} />
                &nbsp;ENKI Protocol.
            </Placeholdertext>
            <JoinText>Join our community</JoinText>
            <MediaWrapper>
                <a
                    href="https://twitter.com/ENKIProtocol"
                    target="_blank"
                    rel="noreferrer"
                >
                    <MediaImg src={twitter} />
                </a>
                <a
                    href="https://t.me/+TiJQ20r2WyUxYzIx"
                    target="_blank"
                    rel="noreferrer"
                >
                    <MediaImg src={require('assets/images/ic_telegram.png')} />
                </a>
                <a
                    href="https://medium.com/@ENKIProtocol"
                    target="_blank"
                    rel="noreferrer"
                >
                    <MediaImg src={require('assets/images/ic_medium.png')} />
                </a>
            </MediaWrapper>
        </Wrapper>
    );
}
