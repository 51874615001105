import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Link, TYPE } from 'theme';
import TranscationModal from 'components/TranscationModal';
import ListItem from 'components/TranscationModal/listItem';
import Checkbox from 'components/CheckBox';
import Row, { RowBetween } from 'components/Row';
import Button from 'components/Button';
import { AutoColumn } from 'components/Column';
import {
    eMetisSymbol,
    enkiSymbol,
    metisSymbol,
    seMetisSymbol,
} from 'common-helpers/img';
import TooltipInfo from 'components/TooltipInfo';
import BigNumber from 'bignumber.js';
import TokenInput from 'components/TokenInput';
import AddTokenToWallet from 'components/AddTokenToWallet';
import { useConstantToken } from 'hooks/useConstantToken';
import { useSeMetisVault } from 'hooks/useContract';
import useTokenApprove from 'hooks/useTokenApprove';
import useSend from 'hooks/useSend';
import user from 'stores/user';
import { observer } from 'mobx-react';
import useVestingMethods from 'hooks/useVestingMethods';
import useSeMetisInfo from 'hooks/useSeMetisInfo';
import useTokenBalance from 'hooks/useTokenBalance';
import { useGetEstimatedGas } from 'hooks/useEstimateGas';
import Numeral from 'numeral';
import useEnkiInfo from 'hooks/useEnkiInfo';
import useExploreLink from 'hooks/useExploreLink';
import { useGetGlobalProfit } from 'hooks/useGetRewards';
import useConfigContract from 'hooks/useConfigContract';
import useSequencerInfo from 'hooks/useSequencerInfo';
import { useGetTokenPrice } from 'hooks/useTokenPrice';
import useUserProfit from 'hooks/useUserProfit';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 64px;
    width: 100%;
    flex-direction: column;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 32px;
    `};
`;

const Overview = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background: #fff;
    border-radius: 24px;
    flex-wrap: wrap;
    overflow: hidden;
    width: 1200px;
    height: 220px;
    box-sizing: border-box;
    padding: 10px;
    flex-wrap: wrap;
    box-shadow: 0px 10px 10px 0px rgba(219, 154, 50, 0.1);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 260px;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    justify-content: flex-start;
    `};
`;

const OverviewItem = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    height: 140px;
    width: 24%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 20px;
    gap: 10px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
    gap: 5px;
    height: 120px;
    `};
`;

const Title = styled.div`
    font-size: 34px;
    color: #0e0905;
    font-weight: bold;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 24px;
    `};
`;

const Desc = styled.div`
    font-size: 24px;
    color: #0e0905;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 16px;
    `};
`;

const MainWrapper = styled.div`
    width: 1200px;
    height: 741px;
    background: #0e0905 linear-gradient(180deg, #ffeaa8 0%, #ffffff 50%);
    border-radius: 24px;
    padding: 0 280px;
    box-sizing: border-box;
    gap: 48px;
    margin-top: 40px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 0 10px;
    padding-bottom: 20px;
    height: auto;
    `};
`;

const MainHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transform: translateY(-50%);
    padding-bottom: 20px;
    transition: all 0.1s linear;
    &.unstake {
        flex-direction: row-reverse;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
      justify-content: center;
      gap: 20px;
    `};
    .info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 32px;
        position: relative;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        gap: 12px;
        `};
        .imgContainer {
            width: 120px;
            height: 120px;
            border-radius: 60px;
            overflow: hidden;
            background: #fffae5;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 80px;
                height: 80px;
            }

            ${({ theme }) => theme.mediaWidth.upToSmall`
             width: 80px;
            height: 80px;
            border-radius: 40px;

            img {
                width: 60px;
                height: 60px;
            }
            `};
        }

        .tokenTitle {
            position: absolute;
            bottom: -70px;
            left: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            ${({ theme }) => theme.mediaWidth.upToSmall`
            left: 15px;
            `};
        }
    }
`;

const InfoWrapper = styled(AutoColumn)`
    margin-top: 50px;
    width: 100%;
    border-top: 1px solid #e8dbd8;
    padding-top: 56px;
    width: 100%;
    gap: 24px;
`;

const InfoImg = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`;

const ButtonGroup = styled(Row)`
    gap: 40px;
    margin-top: 80px;

    button {
        width: 200px;
    }
`;

const InfoListWrappers = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 1200px;
    margin-top: 64px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    margin-top: 32px;
    width: 100%;
    gap: 32px;
    `};
`;

const InfoListWrapper = styled.div`
    width: 100%;
`;

const InfoList = styled(AutoColumn)`
    width: 580px;
    background: #ffffff;
    border-radius: 24px;
    margin-top: 28px;
    gap: 24px;
    box-sizing: border-box;
    padding: 24px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 10px;
    width: 100%;

     div {
        flex-shrink: 1;
    }
    `};
`;

const VestWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    width: 1200px;
    border-radius: 24px;
    gap: 24px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 32px;
    width: 100%;
    `};
`;

const VestContent = styled.div`
    width: 1200px;
    background: #ffffff;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    box-sizing: border-box;
    padding: 36px 24px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    width: 100%;
    padding: 20px 10px;
    gap: 32px;
    `};

    .item {
        width: 472px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;
        .stakeButton {
            width: 200px;
            ${({ theme }) => theme.mediaWidth.upToSmall`
            width: 100px;
            margin-top: 20px;
            `};
        }

        ${({ theme }) => theme.mediaWidth.upToSmall`
            width: 100%;
            gap: 12px;

            > div {
                width: 100%;
            }

            div {
                flex-shrink: 1 !important;
            }
        `};
    }
`;

const ProgressBar = styled.div`
    position: relative;
    width: 100%;
    height: 24px;
    background: #fff6d7;
    border-radius: 4px;
    z-index: 0;
`;

const Progress = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0%;
    background: #fdd436;
    border-radius: 4px;
    box-sizing: border-box;
    color: #000;
    display: flex;
    font-size: 14px;
    align-items: center;
    padding-left: 8px;
    justify-content: flex-start;
    z-index: 1;
`;

const StakeSwitchWrapper = styled.div`
    width: 255px;
    height: 50px;

    background: #fffdf6;

    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 6px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 auto;
    `};
`;

const StakeItem = styled.div`
    text-decoration: none;
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    cursor: pointer;
    height: 100%;

    &.active {
        font-weight: bold;
        background-color: #fdd436;

        border-radius: 5px;
    }
`;

const StakeEMetisWrapper = styled(StakeSwitchWrapper)`
    width: 332px;
    background: #fff;
    height: 64px;
`;

const StakeEMetisItem = styled(StakeItem)`
    font-size: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    `};
`;

const ClaimEMetisButton = styled(Button)`
    background: #d297462e !important;
    color: #d29746 !important;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 auto;
    `};
`;

const RightBorder = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 43px;
    background-color: #8b4c3b;
    transform: translateY(-50%);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    `};
`;

function Stake() {
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [eMetisInputTokenAmount, setEMetisInputTokenAmount] = useState(
        new BigNumber(0),
    );
    const [seMetisInputTokenAmount, setSeMetisInputTokenAmount] = useState(
        new BigNumber(0),
    );
    const [inputEnki, setInputEnki] = useState(new BigNumber(0));
    const { eMetis, seMetis, enki } = useConstantToken();
    const [isStake, setIsStake] = useState(true);
    const [isStakeENKI, setIsStakeENKI] = useState(true);
    const seMetisVaultContract = useSeMetisVault();
    const { getAddressUrl } = useExploreLink();
    const [eMetisInputTokenValid, setEMetisInputValid] = useState(false);
    const [seMetisInputTokenValid, setSeMetisInputValid] = useState(false);
    const [inputENKIValid, setInputENKIValid] = useState(false);
    const { exchangeRate, totalSupply: seMetisTotalSupply } = useSeMetisInfo();
    const { vestRatio } = useConfigContract();
    const { totalSupply: enkiTotalSupply } = useEnkiInfo();
    const { totalProfit: globalProfit, stakingApr } = useGetGlobalProfit();
    const { sequencerInfo, getSequencerInfoFunc } = useSequencerInfo();
    const { balance: seMetisBalance } = useTokenBalance(seMetis.address);
    const { balance: enkiBalance } = useTokenBalance(enki.address);
    const { profit } = useUserProfit();
    const enkiPrice = useGetTokenPrice(enki.address);
    const {
        vestingContract,
        userStakeENKIBalance,
        claimableAmnount,
        cumulativeClaimAmount,
        getEstimateFinishedTime,
        getCanClaimAbleAmount,
        getUserInfo,
        leftDays,
        userLockEMetisBalance,
        claimedAmount,
    } = useVestingMethods();

    const send = useSend();
    const [isOpenSeMetisVaultTransModal, setIsOpenSeMetisVaultTransModal] =
        useState(false);

    const [isOpenENKITransModal, setIsOpenENKITransModal] = useState(false);
    const {
        approved: eMetisApprovedSeMetisVault,
        approve: eMetisApproveSeMetisVault,
    } = useTokenApprove(
        eMetis,
        seMetisVaultContract?.address || '',
        eMetisInputTokenAmount,
    );
    const {
        approved: seMetisApprovedSeMetisVault,
        approve: seMetisApproveSeMetisVault,
    } = useTokenApprove(
        seMetis,
        seMetisVaultContract?.address || '',
        seMetisInputTokenAmount,
    );

    const { approved: approvedVesting, approve: approveVesting } =
        useTokenApprove(enki, vestingContract?.address || '', inputEnki);

    function onCloseSeMetisTransVault() {
        setIsOpenSeMetisVaultTransModal(false);
    }

    async function onSeMetisVaultTransSuccess() {
        setIsOpenSeMetisVaultTransModal(false);
        if (isStake) {
            setEMetisInputTokenAmount(new BigNumber(0));
        } else {
            setSeMetisInputTokenAmount(new BigNumber(0));
        }

        await getUserInfo();
        await getEstimateFinishedTime();
        await getSequencerInfoFunc();
    }

    const estimatedGasStake = useGetEstimatedGas({
        contract: seMetisVaultContract,
        method: 'mint',
        params: [
            eMetisInputTokenAmount.shiftedBy(eMetis.decimals).toFixed(0),
            user.address,
        ],
        show: eMetisInputTokenAmount.isGreaterThan(0),
        fetch:
            eMetisApprovedSeMetisVault &&
            eMetisInputTokenAmount.isGreaterThan(0),
    });

    async function stakeEMetis() {
        if (!seMetisVaultContract) return;

        await send({
            contract: seMetisVaultContract,
            method: 'deposit',
            params: [
                eMetisInputTokenAmount.shiftedBy(eMetis.decimals).toFixed(0),
                user.address,
            ],
            onSuccess: onSeMetisVaultTransSuccess,
        });
    }

    const estimatedGasUnstake = useGetEstimatedGas({
        contract: seMetisVaultContract,
        method: 'redeem',
        params: [
            seMetisInputTokenAmount.shiftedBy(seMetis.decimals).toFixed(0),
            user.address,
            user.address,
        ],
        show: seMetisInputTokenAmount.isGreaterThan(0),
        fetch:
            seMetisApprovedSeMetisVault &&
            seMetisInputTokenAmount.isGreaterThan(0),
    });

    async function unstakeEMetis() {
        if (!seMetisVaultContract) return;

        await send({
            contract: seMetisVaultContract,
            method: 'redeem',
            params: [
                seMetisInputTokenAmount.shiftedBy(seMetis.decimals).toFixed(0),
                user.address,
                user.address,
            ],
            onSuccess: onSeMetisVaultTransSuccess,
        });
    }

    function onCloseENKITransModal() {
        setIsOpenENKITransModal(false);
    }

    function onENKITransSuccess() {
        setIsOpenENKITransModal(false);
        setInputEnki(new BigNumber(0));
        getUserInfo();
        getCanClaimAbleAmount();
    }

    const estimatedGasStakeEnki = useGetEstimatedGas({
        contract: vestingContract,
        method: 'stake',
        params: [new BigNumber(1).shiftedBy(enki.decimals).toFixed(0)],
        show: inputEnki.isGreaterThan(0),
        fetch: approvedVesting && inputEnki.isGreaterThan(0),
    });

    async function stakeEnki() {
        if (!vestingContract) return;

        await send({
            contract: vestingContract,
            method: 'stake',
            params: [inputEnki.shiftedBy(enki.decimals).toFixed(0)],
            onSuccess: onENKITransSuccess,
        });
    }

    async function unstakeEnki() {
        if (!vestingContract) return;

        await send({
            contract: vestingContract,
            method: 'unstake',
            params: [],
            onSuccess: onENKITransSuccess,
        });
    }

    const canClaimEmetisReward = useMemo(() => {
        return claimableAmnount.isGreaterThan(0);
    }, [claimableAmnount]);

    const unlockedEMetisReward = useMemo(() => {
        return claimableAmnount.plus(claimedAmount);
    }, [claimableAmnount, claimedAmount]);

    async function claimVestingEMetis() {
        if (!vestingContract || !canClaimEmetisReward) return;

        await send({
            contract: vestingContract,
            method: 'claim',
            params: [],
            onSuccess: () => {
                getCanClaimAbleAmount();
                getUserInfo();
            },
        });
    }

    const canEMetisClickButton = useMemo(() => {
        return isStake
            ? eMetisInputTokenAmount.isGreaterThan(0) && eMetisInputTokenValid
            : seMetisInputTokenAmount.isGreaterThan(0) &&
                  seMetisInputTokenValid;
    }, [
        eMetisInputTokenValid,
        seMetisInputTokenValid,
        isStake,
        eMetisInputTokenAmount,
        seMetisInputTokenAmount,
    ]);

    const canClickEnkiButton = useMemo(() => {
        return isStakeENKI
            ? inputEnki.isGreaterThan(0) && inputENKIValid
            : userStakeENKIBalance.isGreaterThan(0);
    }, [isStakeENKI, inputENKIValid, inputEnki, userStakeENKIBalance]);

    const eMetisUnlockProgress = useMemo(() => {
        if (userLockEMetisBalance.isEqualTo(0)) return 0;
        if (claimedAmount.isGreaterThanOrEqualTo(userLockEMetisBalance))
            return 100;
        return claimedAmount
            .dividedBy(userLockEMetisBalance)
            .multipliedBy(100)
            .toFixed(2);
    }, [claimedAmount, userLockEMetisBalance]);

    return (
        <Wrapper>
            <Overview>
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            Total seMetis Supply
                        </TYPE.main>
                    </Row>
                    <TYPE.main fontSize={24} fontWeight={600} mobileFz={16}>
                        {seMetisTotalSupply
                            ? Numeral(seMetisTotalSupply).format('0,0')
                            : '--'}{' '}
                        seMetis
                    </TYPE.main>
                    <RightBorder />
                </OverviewItem>
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            Total eMetis Reward
                        </TYPE.main>
                    </Row>
                    <TYPE.main fontSize={24} fontWeight={600} mobileFz={16}>
                        {globalProfit
                            ? Numeral(globalProfit).format('0,0')
                            : '--'}{' '}
                        eMetis
                    </TYPE.main>
                    <RightBorder />
                </OverviewItem>
                {/* <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            ENKI Supply
                        </TYPE.main>
                    </Row>
                    <TYPE.main fontSize={24} fontWeight={600} mobileFz={16}>
                        {enkiTotalSupply
                            ? Numeral(enkiTotalSupply).format('0,0')
                            : '--'}{' '}
                        ENKI
                    </TYPE.main>
                    <RightBorder />
                </OverviewItem> */}
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            Contracts
                        </TYPE.main>
                    </Row>
                    <Link href={getAddressUrl(seMetis.address)}>
                        <TYPE.main
                            fontSize={16}
                            fontWeight={600}
                            mobileFz={16}
                            textAlign="center"
                        >
                            seMetis Vault
                        </TYPE.main>
                    </Link>
                    <Link href={getAddressUrl(enki.address)}>
                        <TYPE.main
                            fontSize={16}
                            fontWeight={600}
                            mobileFz={16}
                            textAlign="center"
                        >
                            ENKI
                        </TYPE.main>
                    </Link>
                </OverviewItem>
            </Overview>
            <AutoColumn justify="center">
                <StakeEMetisWrapper>
                    <StakeEMetisItem
                        className={isStake ? 'active' : ''}
                        onClick={() => {
                            setIsStake(true);
                        }}
                    >
                        Stake
                    </StakeEMetisItem>
                    <StakeEMetisItem
                        onClick={() => {
                            setIsStake(false);
                        }}
                        className={!isStake ? 'active' : ''}
                    >
                        Withdraw
                    </StakeEMetisItem>
                </StakeEMetisWrapper>
            </AutoColumn>
            <MainWrapper>
                <MainHeader className={!isStake ? 'unstake' : ''}>
                    <div className="info">
                        <div className="imgContainer">
                            <img src={eMetis.img} />
                        </div>
                        <div className="tokenTitle">
                            <TYPE.main
                                fontSize={18}
                                fontWeight={600}
                                marginRight="8px"
                            >
                                {eMetis.symbol}
                            </TYPE.main>
                            <AddTokenToWallet token={eMetis} />
                        </div>
                    </div>
                    <div
                        className="info"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setIsStake(!isStake);
                        }}
                    >
                        <div className="imgContainer">
                            <img
                                src={require('assets/images/mintAndStake/ic_switch.png')}
                            />
                        </div>
                    </div>
                    <div className="info">
                        <div className="imgContainer">
                            <img src={seMetis.img} />
                        </div>
                        <div className="tokenTitle">
                            <TYPE.main
                                fontSize={18}
                                fontWeight={600}
                                marginRight="8px"
                            >
                                {seMetis.symbol}
                            </TYPE.main>
                            <AddTokenToWallet token={seMetis} />
                        </div>
                    </div>
                </MainHeader>
                <TokenInput
                    value={eMetisInputTokenAmount}
                    inputPercent={[50]}
                    onChange={setEMetisInputTokenAmount}
                    tokenImage={eMetis.img}
                    token={eMetis}
                    onInputValid={setEMetisInputValid}
                    show={isStake}
                />
                <TokenInput
                    value={seMetisInputTokenAmount}
                    inputPercent={[50]}
                    onChange={setSeMetisInputTokenAmount}
                    tokenImage={seMetis.img}
                    token={seMetis}
                    onInputValid={setSeMetisInputValid}
                    show={!isStake}
                />
                <InfoWrapper>
                    <RowBetween>
                        <TYPE.main fontSize={14}>You Will Receive</TYPE.main>
                        <Row style={{ width: 'auto' }}>
                            <InfoImg
                                src={isStake ? seMetisSymbol : eMetisSymbol}
                            />
                            <TYPE.main fontSize={14} fontWeight={600}>
                                {isStake
                                    ? eMetisInputTokenAmount
                                          .multipliedBy(exchangeRate)
                                          .toFixed()
                                    : seMetisInputTokenAmount
                                          .dividedBy(exchangeRate)
                                          .toFixed()}{' '}
                                {isStake ? 'seMetis' : 'eMetis'}
                            </TYPE.main>
                        </Row>
                    </RowBetween>
                    <RowBetween>
                        <TYPE.main fontSize={14}>Exchange Rate</TYPE.main>
                        <TYPE.main fontSize={14}>
                            1 {eMetis.symbol} = {exchangeRate} {seMetis.symbol}
                        </TYPE.main>
                    </RowBetween>
                    <RowBetween>
                        <TYPE.main fontSize={14}>Estimated Gas Fee</TYPE.main>
                        {isStake ? (
                            <TYPE.main fontSize={14}>
                                {eMetisApprovedSeMetisVault
                                    ? `${estimatedGasStake} METIS`
                                    : 'Approve first'}
                            </TYPE.main>
                        ) : (
                            <TYPE.main fontSize={14}>
                                {seMetisApprovedSeMetisVault
                                    ? `${estimatedGasUnstake} METIS`
                                    : 'Approve first'}
                            </TYPE.main>
                        )}
                    </RowBetween>
                </InfoWrapper>
                <ButtonGroup justify="center">
                    {/* <Button type="outline">Mint</Button> */}
                    <Button
                        disabled={!canEMetisClickButton}
                        onClick={() => {
                            setIsOpenSeMetisVaultTransModal(true);
                        }}
                    >
                        {isStake ? 'Stake' : 'Withdraw'}
                    </Button>
                </ButtonGroup>
            </MainWrapper>
            <InfoListWrappers>
                <InfoListWrapper>
                    <AutoColumn gap="12px">
                        <Title>Rewards</Title>
                        <Desc>Track your staking reward</Desc>
                    </AutoColumn>
                    <InfoList>
                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    Total Exchange Rate-based Rewards
                                </TYPE.main>
                                <TooltipInfo text="The total amount of rewards is obtained by staking eMetis" />
                            </Row>
                            <Row style={{ width: 'auto', flexShrink: 0 }}>
                                <InfoImg src={eMetisSymbol} />
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    {Numeral(profit.value).format('0,0.[0000]')}{' '}
                                    eMetis
                                </TYPE.main>
                            </Row>
                        </RowBetween>
                        <RowBetween>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                Your seMetis Amount
                            </TYPE.main>

                            <Row style={{ width: 'auto' }}>
                                <InfoImg src={seMetisSymbol} />
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    {seMetisBalance.toFixed(3)} seMetis
                                </TYPE.main>
                            </Row>
                        </RowBetween>
                        <RowBetween>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                Your eMetis Reward
                            </TYPE.main>
                            <Row style={{ width: 'auto' }}>
                                <InfoImg src={eMetisSymbol} />
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    -- eMetis
                                </TYPE.main>
                            </Row>
                        </RowBetween>
                        <RowBetween>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                Share of the pool
                            </TYPE.main>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                0.0114 %
                            </TYPE.main>
                        </RowBetween>
                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    Your Exchange Rate
                                </TYPE.main>
                                <TooltipInfo text="Exchange rates for eMetis and seMetis" />
                            </Row>

                            <TYPE.main fontSize={16} mobileFz={12}>
                                {exchangeRate
                                    ? (1 / exchangeRate).toFixed(3)
                                    : '--'}{' '}
                                eMetis Per seMetis
                            </TYPE.main>
                        </RowBetween>
                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    Your Estimated APR
                                </TYPE.main>
                                <TooltipInfo text="eMetis’ staking yield" />
                            </Row>

                            <TYPE.main fontSize={16} mobileFz={12}>
                                {stakingApr
                                    ? Numeral(stakingApr).format('0.00 %')
                                    : '--'}
                            </TYPE.main>
                        </RowBetween>
                        <Row justify="flex-end">
                            <Link
                                href="#"
                                fontSize="14px"
                                mbFrontSize="12px"
                                disableText="Not available on Testnet"
                            >
                                Check On Dashboard
                            </Link>
                        </Row>
                    </InfoList>
                </InfoListWrapper>
                <InfoListWrapper>
                    <AutoColumn gap="12px">
                        <Title>Sequencer Node Info</Title>
                        <Desc>Check Sequencer details</Desc>
                    </AutoColumn>
                    <InfoList>
                        <RowBetween>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                Current METIS staked on L1
                            </TYPE.main>
                            <Row style={{ width: 'auto' }}>
                                <InfoImg src={metisSymbol} />
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    {sequencerInfo
                                        ? Numeral(
                                              sequencerInfo.l1Staked,
                                          ).format('0,0')
                                        : '--'}{' '}
                                    METIS
                                </TYPE.main>
                            </Row>
                        </RowBetween>
                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    Syncing to L1 sequencer
                                </TYPE.main>
                                <TooltipInfo text="The total amount of METIS sent to layer1" />
                            </Row>

                            <Row style={{ width: 'auto', flexShrink: 0 }}>
                                <InfoImg src={metisSymbol} />
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    {sequencerInfo
                                        ? Numeral(
                                              sequencerInfo.syncingToL1,
                                          ).format('0,0')
                                        : '--'}{' '}
                                    METIS
                                </TYPE.main>
                            </Row>
                        </RowBetween>
                        <RowBetween>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                Sequencer Node APR
                            </TYPE.main>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                {sequencerInfo
                                    ? Numeral(sequencerInfo.apr).format(
                                          '0,0.[00]',
                                      )
                                    : '--'}{' '}
                                %
                            </TYPE.main>
                        </RowBetween>
                        <RowBetween>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                Total sequencer rewards
                            </TYPE.main>
                            <Row style={{ width: 'auto', flexShrink: 0 }}>
                                <InfoImg src={eMetisSymbol} />

                                <TYPE.main fontSize={16} mobileFz={12}>
                                    {sequencerInfo
                                        ? Numeral(
                                              sequencerInfo.totalRewards,
                                          ).format('0,0')
                                        : '--'}{' '}
                                    eMetis
                                </TYPE.main>
                            </Row>
                        </RowBetween>
                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    Last sync rewards
                                </TYPE.main>
                                <TooltipInfo text="The last time rewards were distributed from layer1" />
                            </Row>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                Not available on testnet
                            </TYPE.main>
                        </RowBetween>
                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    Next staked sync
                                </TYPE.main>
                                <TooltipInfo text="The next time the staking data is updated" />
                            </Row>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                Not available on testnet
                            </TYPE.main>
                        </RowBetween>
                        <Row justify="flex-end" gap="20px">
                            <Link
                                href="#"
                                fontSize="14px"
                                mbFrontSize="12px"
                                disableText="Not available on Testnet"
                            >
                                Check on Metis Sequencer
                            </Link>
                            <Link
                                href="#"
                                fontSize="14px"
                                mbFrontSize="12px"
                                disableText="Not available on Testnet"
                            >
                                Check On Dashboard
                            </Link>
                        </Row>
                    </InfoList>
                </InfoListWrapper>
            </InfoListWrappers>
            <VestWrapper>
                <AutoColumn gap="12px" justify="flex-start">
                    <Title>Vest</Title>
                    <Desc>
                        Stake ENKI to unlock additional {vestRatio}% eMetis
                        rewards
                    </Desc>
                </AutoColumn>
                <VestContent>
                    <AutoColumn className="item">
                        <RowBetween>
                            <Row flexShrink={1}>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    ENKI
                                </TYPE.main>
                                {/* <AddTokenToWallet token={enki} /> */}
                            </Row>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                ${enkiPrice || '-'}
                            </TYPE.main>
                        </RowBetween>
                        <RowBetween>
                            <Row style={{ width: 'auto' }}>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    Your ENKI Balance
                                </TYPE.main>
                            </Row>

                            <Row style={{ width: 'auto', flexShrink: 0 }}>
                                <InfoImg src={enkiSymbol} />
                                <TYPE.main fontSize={16} mobileFz={12} shrink>
                                    {enkiBalance.toFixed(3)} ENKI
                                </TYPE.main>
                            </Row>
                        </RowBetween>
                        <RowBetween>
                            <Row style={{ width: 'auto' }}>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    Your Staked
                                </TYPE.main>
                                <TooltipInfo text="Your staked ENKI in vesting" />
                            </Row>

                            <Row style={{ width: 'auto', flexShrink: 0 }}>
                                <InfoImg src={enkiSymbol} />
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    {userStakeENKIBalance.toFixed(3)} ENKI
                                </TYPE.main>
                            </Row>
                        </RowBetween>
                        <RowBetween>
                            <Row style={{ width: 'auto' }}>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    Vest Status
                                </TYPE.main>
                                <TooltipInfo text="Vest contract status currently" />
                            </Row>

                            <TYPE.main fontSize={16} mobileFz={12}>
                                {user.connected &&
                                userStakeENKIBalance.isGreaterThan(0)
                                    ? 'Active'
                                    : 'NOT ACTIVE'}
                            </TYPE.main>
                        </RowBetween>
                        <RowBetween style={{ flexWrap: 'wrap' }}>
                            <Row style={{ width: 'auto', flexShrink: 0 }}>
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    eMetis Reward Unlocked
                                </TYPE.main>
                                <TooltipInfo text="Unlocked eMetis Reward " />
                            </Row>

                            <Row style={{ width: 'auto', flexShrink: 0 }}>
                                <InfoImg src={eMetis.img} />
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    {unlockedEMetisReward.toFixed()} eMetis (
                                    Unlocked {eMetisUnlockProgress} %)
                                </TYPE.main>
                            </Row>
                        </RowBetween>
                        <RowBetween marginTop="20px">
                            <TYPE.main fontSize={16} mobileFz={12}>
                                {claimedAmount.toFixed()}
                            </TYPE.main>

                            <Row style={{ width: 'auto', flexShrink: 0 }}>
                                <InfoImg src={eMetisSymbol} />
                                <TYPE.main fontSize={16} mobileFz={12}>
                                    {userLockEMetisBalance.toFixed(3)} eMetis
                                </TYPE.main>
                            </Row>
                        </RowBetween>
                        <Row>
                            <ProgressBar>
                                <Progress
                                    style={{
                                        width: `${eMetisUnlockProgress}%`,
                                    }}
                                >
                                    {eMetisUnlockProgress}%
                                </Progress>
                            </ProgressBar>
                        </Row>
                        <RowBetween>
                            <TYPE.main fontSize={16} mobileFz={12}>
                                eMetis Reward
                            </TYPE.main>

                            <TYPE.main
                                fontSize={16}
                                mobileFz={12}
                                color="#FBC31C"
                            >
                                {leftDays} Days
                            </TYPE.main>
                        </RowBetween>
                        <Row marginTop="64px">
                            <ClaimEMetisButton
                                disabled={!canClaimEmetisReward}
                                onClick={() => {
                                    claimVestingEMetis();
                                }}
                            >
                                Claim eMetis
                            </ClaimEMetisButton>
                        </Row>
                    </AutoColumn>

                    <div className="item">
                        <AutoColumn gap="12px" justify="flex-start">
                            <Row justify="flex-end">
                                <StakeSwitchWrapper>
                                    <StakeItem
                                        className={isStakeENKI ? 'active' : ''}
                                        onClick={() => {
                                            setIsStakeENKI(true);
                                        }}
                                    >
                                        Stake ENKI
                                    </StakeItem>
                                    <StakeItem
                                        onClick={() => {
                                            setIsStakeENKI(false);
                                        }}
                                        className={!isStakeENKI ? 'active' : ''}
                                    >
                                        Unstake ENKI
                                    </StakeItem>
                                </StakeSwitchWrapper>
                            </Row>
                            <Row>
                                {isStakeENKI ? (
                                    <TokenInput
                                        value={inputEnki}
                                        onChange={setInputEnki}
                                        tokenImage={enkiSymbol}
                                        token={enki}
                                        onInputValid={setInputENKIValid}
                                    />
                                ) : (
                                    <TokenInput
                                        value={userStakeENKIBalance}
                                        onChange={setInputEnki}
                                        tokenImage={enkiSymbol}
                                        token={enki}
                                        disabled
                                    />
                                )}
                            </Row>
                            <RowBetween marginTop="28px">
                                <TYPE.main
                                    fontSize={16}
                                    mobileFz={14}
                                    fontWeight={600}
                                >
                                    Estimated Unlock Rewards
                                </TYPE.main>

                                <TYPE.main fontSize={16} mobileFz={14}>
                                    {claimedAmount.toFixed(3)} eMetis ( Unlocked{' '}
                                    {eMetisUnlockProgress} %)
                                </TYPE.main>
                            </RowBetween>
                            {isStakeENKI && (
                                <>
                                    <RowBetween>
                                        <TYPE.main fontSize={16} mb={14}>
                                            Release Cycle
                                        </TYPE.main>

                                        <TYPE.main fontSize={16} mb={14}>
                                            {leftDays} Days
                                        </TYPE.main>
                                    </RowBetween>
                                    <RowBetween>
                                        <TYPE.main fontSize={16} mb={14}>
                                            Estimated Gas Fee{' '}
                                        </TYPE.main>

                                        <TYPE.main fontSize={16} mb={14}>
                                            {approvedVesting
                                                ? `${estimatedGasStakeEnki} METIS`
                                                : 'Approve first'}
                                        </TYPE.main>
                                    </RowBetween>
                                </>
                            )}
                        </AutoColumn>
                        <Row justify="center">
                            <Button
                                className="stakeButton"
                                disabled={!canClickEnkiButton}
                                onClick={() => {
                                    setIsOpenENKITransModal(true);
                                }}
                            >
                                {isStakeENKI ? 'Stake' : 'Unstake'}
                            </Button>
                        </Row>
                    </div>
                </VestContent>
            </VestWrapper>
            <TranscationModal
                isOpen={isOpenSeMetisVaultTransModal}
                onClose={onCloseSeMetisTransVault}
                title={isStake ? 'Stake eMetis' : 'Withdraw eMetis'}
                amountDesc={isStake ? 'Amount to stake' : 'Amount to withdraw'}
                amount={(isStake
                    ? eMetisInputTokenAmount
                    : seMetisInputTokenAmount
                ).toFixed()}
                token={eMetis}
            >
                {isStake ? (
                    <>
                        <ListItem type="init" text={'Initiate staking'} />
                        <ListItem
                            type={'seMetis'}
                            text={`receive ${eMetisInputTokenAmount
                                .multipliedBy(exchangeRate)
                                .toFixed()} seMetis`}
                        />
                        <ListItem type="calender" text="Wait 7 days" />
                        <ListItem type="send" text="Start earning" />
                    </>
                ) : (
                    <>
                        <ListItem type="init" text={'Initiate withdrawing'} />
                        <ListItem
                            type={'seMetis'}
                            text={`Receive ${Numeral(
                                seMetisInputTokenAmount
                                    .dividedBy(exchangeRate)
                                    .multipliedBy(0.7),
                            ).format(
                                '0,0.[000000]',
                            )} eMetis(account for 70% of the reward) `}
                        />
                        <ListItem
                            type="send"
                            text={`The remaining 30% of the rewards will be released through staking enki`}
                        />
                    </>
                )}

                <Checkbox
                    checked={checked1}
                    onCheck={setChecked1}
                    text="I understand that due to the 7-day bridging process from L2 to L1, staking rewards will start after 7 days."
                />
                <Checkbox
                    checked={checked2}
                    onCheck={setChecked2}
                    text="I understand that 70% of the eMetis rewards earned from eMetis staking will be converted upon redeeming seMetis. The remaining 30% of the rewards will be vested and can be released by staking ENKI within 365 days."
                />
                <Row justify="center" gap="40px" marginTop="20px">
                    {((isStake && !eMetisApprovedSeMetisVault) ||
                        (!isStake && !seMetisApprovedSeMetisVault)) && (
                        <Button
                            disabled={!checked1 || !checked2}
                            onClick={
                                isStake
                                    ? eMetisApproveSeMetisVault
                                    : seMetisApproveSeMetisVault
                            }
                        >
                            Approve
                        </Button>
                    )}
                    {isStake ? (
                        <Button
                            disabled={
                                !checked1 ||
                                !checked2 ||
                                !eMetisApprovedSeMetisVault
                            }
                            onClick={stakeEMetis}
                        >
                            Stake
                        </Button>
                    ) : (
                        <Button
                            disabled={!seMetisApprovedSeMetisVault}
                            onClick={unstakeEMetis}
                        >
                            Withdraw
                        </Button>
                    )}
                </Row>
                {((isStake && !eMetisApprovedSeMetisVault) ||
                    (!isStake && !seMetisApprovedSeMetisVault)) && (
                    <TYPE.desc>
                        *To preserve your control and agency, you need to
                        customize how many tokens you allow dapp to access in
                        your wallet.
                    </TYPE.desc>
                )}
            </TranscationModal>
            <TranscationModal
                isOpen={isOpenENKITransModal}
                onClose={onCloseENKITransModal}
                title={isStakeENKI ? 'Stake enki' : 'Withdraw enki'}
                amountDesc={isStakeENKI ? `Start staking ` : `Start unstaking `}
                amount={
                    isStakeENKI
                        ? inputEnki.toFixed()
                        : userStakeENKIBalance.toFixed()
                }
                token={enki}
            >
                {isStakeENKI ? (
                    <>
                        <ListItem
                            type="init"
                            text={
                                isStakeENKI
                                    ? 'Initiate staking'
                                    : 'Initiate unstaking'
                            }
                        />
                        <ListItem
                            type={'eMetis'}
                            text={
                                isStakeENKI
                                    ? `Start releaseing  ${userLockEMetisBalance.toFixed()} eMetis`
                                    : `Stop unstaking ${userLockEMetisBalance.toFixed()} eMetis`
                            }
                        />
                        <ListItem
                            type="send"
                            text={`${userLockEMetisBalance.toFixed()} eMetis will be released within 365 days`}
                        />
                    </>
                ) : (
                    <>
                        <ListItem
                            type="init"
                            text="When you redeem the locked ENKI,your rewards will stop releasing."
                        />
                    </>
                )}

                <Row justify="center" gap="40px" marginTop="20px">
                    {!approvedVesting && (
                        <Button onClick={approveVesting}>Approve</Button>
                    )}
                    {isStakeENKI ? (
                        <Button disabled={!approvedVesting} onClick={stakeEnki}>
                            Stake
                        </Button>
                    ) : (
                        <Button
                            disabled={!approveVesting}
                            onClick={unstakeEnki}
                        >
                            Withdraw
                        </Button>
                    )}
                </Row>
                {!approvedVesting && (
                    <TYPE.desc>
                        *To preserve your control and agency, you need to
                        customize how many tokens you allow dapp to access in
                        your wallet.
                    </TYPE.desc>
                )}
            </TranscationModal>
        </Wrapper>
    );
}

export default observer(Stake);
