import { ChainId } from 'constant';
import user from 'stores/user';

export default function useGetAvailableText() {
    const { chainId } = user;

    return function (text: any, dom?: any) {
        if (chainId === ChainId.mainnet) {
            return text;
        } else {
            return dom || 'Not available on testnet';
        }
    };
}
