import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import Config from './config';
import { ChainId, DefaultChainId, SupportChainIDs } from 'constant';

export function getCurrentChainId(): ChainId {
    const web3ChainId = Number(
        ((window as any)?.web3 as any)?.currentProvider.chainId,
    );

    return SupportChainIDs.indexOf(web3ChainId) >= 0
        ? web3ChainId
        : DefaultChainId;
}

export function getApolloClient(key: string, chainId?: ChainId) {
    console.log('client with chain', chainId || getCurrentChainId());
    return new ApolloClient({
        cache: new InMemoryCache(),
        link: new HttpLink({
            uri: (Config[chainId || getCurrentChainId()] as any)[key],
        }),
    });
}
