import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

import Row from 'components/Row';

import { AutoColumn } from 'components/Column';

import ReactECharts from 'echarts-for-react';
import Table from 'components/Table';
import useTokenBalance from 'hooks/useTokenBalance';
import { useConstantToken } from 'hooks/useConstantToken';
import useVestingMethods from 'hooks/useVestingMethods';
import useSeMetisInfo from 'hooks/useSeMetisInfo';
import {
    useGetDailyProfit,
    useGetGlobalProfit,
    useGetMonthChartData,
} from 'hooks/useGetRewards';
import { MRT_ColumnDef } from 'material-react-table';
import useGetAvailableText from 'hooks/useAvailableText';
import Numeral from 'numeral';
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 64px;
    width: 100%;
    flex-direction: column;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 32px;
    `};
`;

const Overview = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background: #fff;
    border-radius: 24px;
    flex-wrap: wrap;
    overflow: hidden;
    width: 1200px;

    height: 220px;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 10px 10px 0px rgba(219, 154, 50, 0.1);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 200px;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    `};
`;

const OverviewItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    height: 140px;
    width: 24%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 20px;
    gap: 10px;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
    padding: 10px;
    height: 90px;
    `};
`;

const RightBorder = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 43px;
    background-color: #8b4c3b;
    transform: translateY(-50%);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    `};
`;
const Title = styled.div`
    font-size: 34px;
    color: #0e0905;
    font-weight: bold;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 24px;
    `};
`;

const Desc = styled.div`
    font-size: 24px;
    color: #0e0905;
    text-align: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 16px;
    `};
`;

const MainWrapper = styled.div`
    width: 1200px;
    background: linear-gradient(180deg, #ffeaa8 0%, #ffffff 100%);
    border-radius: 24px;
    box-sizing: border-box;
    gap: 48px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 0 10px;
    padding-bottom: 20px;
    `};
`;

const MainHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 48px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    `};
`;

const ChartWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 48px;
    background: linear-gradient(180deg, #ffeaa8 0%, #ffffff 60%);
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 10px;
    `};
`;

const TableWrapper = styled.div`
    display: flex;
    padding: 0 110px;
    align-items: center;
    justify-content: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    `};
`;

export default function Rewards() {
    const { seMetis, eMetis } = useConstantToken();
    const ava = useGetAvailableText();
    const { balance: seMetisBalance } = useTokenBalance(seMetis.address);
    const { exchangeRate } = useSeMetisInfo();
    const { claimableAmnount, lastVestingTime } = useVestingMethods();
    const { stakingApr } = useGetGlobalProfit();
    const startTime = lastVestingTime
        ? Math.floor(lastVestingTime / 1000)
        : undefined;

    const { profits, isLoading: isLoadingTable } = useGetDailyProfit(startTime);
    const { monthlyProfit, totalProfit, estimateProfit, actualProfit } =
        useGetMonthChartData(startTime);

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'date', //access nested data with dot notation
                header: 'Date',
            },
            {
                accessorKey: 'dailyReward',
                header: 'Earnings(eMetis)',
            },
            {
                accessorKey: 'apr',
                header: 'APR',
            },
        ],
        [],
    );

    const options = useMemo(() => {
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                backgroundColor: '#FFF8E4',
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: [
                {
                    type: 'category',
                    data: [
                        '08/2023',
                        '09/2023',
                        '10/2023',
                        '11/2023',
                        '12/2023',
                    ],
                },
            ],
            yAxis: [
                {
                    type: 'value',
                },
            ],
            series: [
                {
                    name: 'Project(eMetis)',
                    type: 'bar',
                    stack: 'stack',
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#FDBC36',
                    },
                    data: estimateProfit,
                },
                {
                    name: 'Actual(eMetis)',
                    type: 'bar',
                    stack: 'stack',
                    emphasis: {
                        focus: 'series',
                    },
                    itemStyle: {
                        color: '#FDD436',
                    },
                    data: actualProfit,
                },
            ],
        };
    }, [estimateProfit, actualProfit]);

    return (
        <Wrapper>
            <AutoColumn justify="center">
                <Title>Reward History</Title>
                <Desc>Track your seMetis earnings over time.</Desc>
            </AutoColumn>
            <Overview>
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            Your seMetis balance
                        </TYPE.main>
                    </Row>
                    <TYPE.main fontSize={24} fontWeight={600} mobileFz={16}>
                        {seMetisBalance.toFixed(3)} seMetis
                    </TYPE.main>
                    <RightBorder />
                </OverviewItem>
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            Your pending eMetis reward
                        </TYPE.main>
                    </Row>
                    <TYPE.main fontSize={24} fontWeight={600} mobileFz={16}>
                        {claimableAmnount.toFixed(3)} eMetis
                    </TYPE.main>
                    <RightBorder />
                </OverviewItem>
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            Estimated seMetis APR
                        </TYPE.main>
                    </Row>
                    <TYPE.main fontSize={24} fontWeight={600} mobileFz={16}>
                        {stakingApr
                            ? Numeral(stakingApr).format('0.00 %')
                            : '--'}
                    </TYPE.main>

                    <RightBorder />
                </OverviewItem>
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            eMetis Per seMetis
                        </TYPE.main>
                    </Row>
                    <TYPE.main fontSize={24} fontWeight={600} mobileFz={16}>
                        {exchangeRate ? (1 / exchangeRate).toFixed(3) : '--'}{' '}
                        eMetis
                    </TYPE.main>
                </OverviewItem>
            </Overview>

            <MainWrapper>
                <Row>
                    <ChartWrapper>
                        <MainHeader>
                            <Row
                                gap="30px"
                                style={{ width: 'auto', flexShrink: 0 }}
                            >
                                <TYPE.main fontSize={18} mobileFz={12}>
                                    Est.Rewards 2023
                                </TYPE.main>
                                <TYPE.main
                                    fontSize={18}
                                    fontWeight={600}
                                    mobileFz={12}
                                >
                                    {totalProfit.toFixed(3)} eMetis
                                </TYPE.main>
                            </Row>
                            <Row
                                gap="30px"
                                style={{ width: 'auto', flexShrink: 0 }}
                            >
                                <TYPE.main fontSize={18} mobileFz={12}>
                                    Est.Monthly 2023
                                </TYPE.main>
                                <TYPE.main
                                    fontSize={18}
                                    fontWeight={600}
                                    mobileFz={12}
                                >
                                    {monthlyProfit.toFixed(3)} eMetis
                                </TYPE.main>
                            </Row>
                        </MainHeader>
                        <ReactECharts
                            option={options}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{ width: '100%', height: 400 }}
                        />
                    </ChartWrapper>
                </Row>
                <TableWrapper>
                    <Table
                        data={profits}
                        columns={columns}
                        enableTopToolbar={false}
                        enableMultiSort={false}
                        enableColumnActions={false}
                        isLoading={isLoadingTable}
                        paginationDisplayMode="pages"
                    />
                </TableWrapper>
                .
            </MainWrapper>
        </Wrapper>
    );
}
