import React from 'react';
import styled from 'styled-components';
import AdjustIcon from '@mui/icons-material/Adjust';
import { TYPE } from 'theme';
import {
    eMetisSymbol,
    enkiSymbol,
    metisSymbol,
    seMetisSymbol,
} from 'common-helpers/img';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TelegramIcon from '@mui/icons-material/Telegram';
const Wrapper = styled.div`
    width: 600px;
    height: 56px;
    background: #fefcfb;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    box-sizing: border-box;
    padding: 20px;
    gap: 16px;
    flex-wrap: wrap;
    height: auto;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 8px;
    
    `};
`;

const InitIcon = styled(AdjustIcon)`
    width: 24px;
    height: 24px;
    color: #000;
`;
const Calender = styled(CalendarMonthIcon)`
    width: 24px;
    height: 24px;
    color: #000;
`;

const SvgIcon = styled.img`
    width: 24px;
    height: 24px;
`;
const Send = styled(TelegramIcon)`
    width: 24px;
    height: 24px;
    color: #000;
`;

interface Props {
    type:
        | 'init'
        | 'metis'
        | 'calender'
        | 'send'
        | 'eMetis'
        | 'seMetis'
        | 'enki';
    text: string;
}
export default function ListItem({ type, text }: Props) {
    return (
        <Wrapper>
            {type === 'init' && <InitIcon />}
            {type === 'metis' && <SvgIcon src={metisSymbol} />}
            {type === 'enki' && <SvgIcon src={enkiSymbol} />}
            {type === 'calender' && <Calender />}
            {type === 'send' && <Send />}
            {type === 'eMetis' && <SvgIcon src={eMetisSymbol} />}
            {type === 'seMetis' && <SvgIcon src={seMetisSymbol} />}
            <TYPE.main fontWeight={600} fontSize={14}>
                {text}
            </TYPE.main>
        </Wrapper>
    );
}
