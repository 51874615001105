import { ChainId } from 'constant';

export enum Client {
    graph = 'graph',
    exchangeSubgraph = 'exchangeSubgraph',
}

export default {
    [ChainId.mainnet]: {
        [Client.graph]: 'https://lsd-server.onrender.com/',
        [Client.exchangeSubgraph]:
            'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/exchange',
    },
    [ChainId.testnet]: {
        [Client.graph]: 'https://lsd-server.onrender.com/',
        [Client.exchangeSubgraph]:
            'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/exchange',
    },
    [ChainId.testnetSepolia]: {
        [Client.graph]: 'https://lsd-server-v2-cezac.ondigitalocean.app/',
        [Client.exchangeSubgraph]:
            'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/exchange',
    },
};
