import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

import Row from 'components/Row';

import Table from 'components/Table';
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 64px;
    width: 100%;
    flex-direction: column;
`;

const MainWrapper = styled.div`
    width: 1200px;
    border-radius: 24px;
    box-sizing: border-box;
    gap: 48px;
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    gap: 20px;
    `};
`;

const MainHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #fffdf7;
    border-radius: 24px;
    height: 100px;

    .item {
        width: 400px;
        box-sizing: border-box;
        padding: 0 32px;
    }
`;

const TableWrapper = styled(Row)`
    background: #fffdf7;
    box-sizing: border-box;
    padding: 20px 40px;
`;

export default function Sequencers() {
    return (
        <Wrapper>
            <MainWrapper>
                <MainHeader>
                    <Row
                        className="item"
                        gap="30px"
                        style={{ width: 'auto', flexShrink: 0 }}
                    >
                        <TYPE.main fontSize={18}>Est.Rewards 2023</TYPE.main>
                        <TYPE.main fontSize={18} fontWeight={600}>
                            $10
                        </TYPE.main>
                    </Row>
                    <Row
                        className="item"
                        gap="30px"
                        style={{ width: 'auto', flexShrink: 0 }}
                    >
                        <TYPE.main fontSize={18}>Est.Monthly 2023</TYPE.main>
                        <TYPE.main fontSize={18} fontWeight={600}>
                            $10
                        </TYPE.main>
                    </Row>
                    <Row
                        className="item"
                        gap="30px"
                        style={{ width: 'auto', flexShrink: 0 }}
                    >
                        <TYPE.main fontSize={18}>Est.Monthly 2023</TYPE.main>
                        <TYPE.main fontSize={18} fontWeight={600}>
                            $10
                        </TYPE.main>
                    </Row>
                </MainHeader>
                <TableWrapper></TableWrapper>
            </MainWrapper>
        </Wrapper>
    );
}
