import { gql } from '@apollo/client';
import { getApolloClient } from '../index';
import { Client } from 'gql/config';

export interface Token {
    id: string;
    symbol: string;
    decimals: number;
    derivedMETIS: number;
}

export interface GraphReturnData {
    tokens: Token[];
    bundle: {
        metisPrice: number;
    };
}

export interface TokenData {
    [key: string]: {
        price: number;
    };
}

export default function getTokens(
    ids: string[],
): Promise<TokenData | undefined> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        getApolloClient(Client.exchangeSubgraph)
            .query<GraphReturnData>({
                query: gql`
                    query tokensPrices($tokenIds: [ID!]) {
                        tokens(where: { id_in: $tokenIds }) {
                            id
                            symbol
                            decimals
                            derivedMETIS
                        }

                        bundle(id: "1") {
                            metisPrice
                        }
                    }
                `,
                variables: {
                    tokenIds: ids,
                },
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                const { data } = res;
                if (data) {
                    const { tokens, bundle } = data;
                    if (bundle?.metisPrice) {
                        const returnData: TokenData = {};

                        for (let i = 0, len = tokens.length; i < len; i++) {
                            const token = tokens[i];
                            returnData[token.id] = {
                                price: Number(
                                    token.derivedMETIS * bundle.metisPrice,
                                ),
                            };
                        }

                        returnData['metis'] = {
                            price: Number(bundle.metisPrice),
                        };

                        return resolve(returnData);
                    }

                    resolve(undefined);
                }
            })
            .catch((e: any) => {
                resolve(undefined);
            });
    });
}
