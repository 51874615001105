import React from 'react';
import styled from 'styled-components';
import { NavLink, Route } from 'react-router-dom';
import Overview from './Overview';
import Balance from './Balance';
import Sequencers from './Sequencers';
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`;

const NavWrapper = styled.div`
    width: 491px;
    height: 64px;
    background: #ffffff;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 6px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const Nav = styled(NavLink)`
    text-decoration: none;
    flex: 1 1 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    cursor: pointer;

    &.active {
        font-weight: bold;
        background-color: #fdd436;
        height: 52px;
        border-radius: 12px;
    }
`;

const ChildWrapper = styled.div`
    width: 100%;

    margin-top: 64px;
`;

const FaqWrapper = styled.div`
    width: 990px;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
  width: calc(100vw - 50px)
  `};
    .MuiPaper-root {
        background: #fff;
        border-radius: 8px;
        box-shadow: none;
        ::before {
            display: none;
        }
    }

    .MuiAccordionSummary-root {
        height: 120px;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        height: 80px
        `};

        .MuiSvgIcon-root {
            fill: #000;
        }
    }
`;

const Title = styled.div`
    font-size: 34px;
    color: #0e0905;
    font-weight: bold;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 24px;
    `};
`;

const Desc = styled.div`
    font-size: 24px;
    color: #0e0905;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 16px;
    `};
`;

export default function Dashboard() {
    return (
        <Wrapper>
            <NavWrapper>
                <Nav
                    id="dash-overview"
                    to="/dashboard/overview"
                    className={(isActive) => (isActive ? 'active' : '')}
                >
                    Overview
                </Nav>
                <Nav
                    id="dash-balance"
                    to="/dashboard/balance"
                    className={(isActive) => (isActive ? 'active' : '')}
                >
                    Balance
                </Nav>
                <Nav
                    id="dash-sequencers"
                    to="/dashboard/sequencers"
                    className={(isActive) => (isActive ? 'active' : '')}
                >
                    Sequencers
                </Nav>
            </NavWrapper>
            <ChildWrapper>
                <Route path="/dashboard/overview">
                    <Overview />
                </Route>
                <Route path="/dashboard/balance">
                    <Balance />
                </Route>
                <Route path="/dashboard/sequencers">
                    <Sequencers />
                </Route>
            </ChildWrapper>
        </Wrapper>
    );
}
