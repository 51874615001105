import { useENKI, useVesting } from './useContract';
import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useConstantToken } from './useConstantToken';

export default function useEnkiInfo() {
    const { enki } = useConstantToken();
    const enkiContract = useENKI();
    const vestingContract = useVesting();
    const [totalSupply, setTotalSupply] = useState(new BigNumber(0));
    const [totalStaked, setTotalStaked] = useState(new BigNumber(0));

    async function getTotalSupply() {
        if (!enkiContract) return;

        try {
            const res = await enkiContract.totalSupply();

            if (res) {
                setTotalSupply(
                    new BigNumber(res?.toString()).shiftedBy(-enki.decimals),
                );
            }
        } catch (e) {
            console.error('get enki TotalSupply error');
            console.error(e);
        }
    }

    async function getEnkiTotalStaked() {
        if (!enkiContract || !vestingContract) return;

        try {
            const res = await enkiContract.balanceOf(vestingContract.address);

            if (res) {
                setTotalStaked(
                    new BigNumber(res?.toString()).shiftedBy(-enki.decimals),
                );
            }
        } catch (e) {
            console.error('getEnkiTotalStaked error');
            console.error(e);
        }
    }

    useEffect(() => {
        getTotalSupply();
    }, [enkiContract]);

    useEffect(() => {
        getEnkiTotalStaked();
    }, [enkiContract, vestingContract]);

    return {
        totalSupply,
        totalStaked,
    };
}
