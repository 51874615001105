import { Deployed, getContracts } from 'gql/contracts';
import { useEffect } from 'react';
import contracts from 'stores/contracts';
import user from 'stores/user';

export default function useGetContractByGraph(): Deployed | undefined {
    async function getContractsFunc() {
        if (contracts.contracts) return;
        const res = await getContracts();

        if (res) {
            contracts.setContract(res);
        } else {
            console.error(`get deployed info error`);
        }
    }

    useEffect(() => {
        getContractsFunc();
    }, [user.chainId, contracts.contracts]);

    return contracts.contracts;
}
