import React, { useMemo } from 'react';
import styled from 'styled-components';
import { NavLink, Route, useLocation } from 'react-router-dom';
import Mint from './mint';
import Stake from './stake';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TYPE } from 'theme';
import { AutoColumn } from 'components/Column';
import Rewards from './rewards';
import faqs from './faq';
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
`;

const NavWrapper = styled.div`
    width: 491px;
    height: 64px;
    background: #ffffff;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 6px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const Nav = styled(NavLink)`
    text-decoration: none;
    flex: 1 1 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    cursor: pointer;

    &.active {
        font-weight: bold;
        background-color: #fdd436;
        height: 52px;
        border-radius: 12px;
    }
`;

const ChildWrapper = styled.div`
    width: 100%;

    margin-top: 64px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 20px;
    `};
`;

const FaqWrapper = styled.div`
    width: 990px;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToSmall`
  width: calc(100vw - 50px)
  `};
    .MuiPaper-root {
        background: #fff;
        border-radius: 8px;
        box-shadow: none;
        ::before {
            display: none;
        }
    }

    .MuiAccordionSummary-root {
        height: 120px;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        height: 80px
        `};

        .MuiSvgIcon-root {
            fill: #000;
        }
    }
`;

const Title = styled.div`
    font-size: 34px;
    color: #0e0905;
    font-weight: bold;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 24px;
    `};
`;

const Desc = styled.div`
    font-size: 24px;
    color: #0e0905;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 16px;
    `};
`;

const FaqBg = styled.div`
    position: absolute;
    top: 0;
    left: 70%;
    transform: translateX(-50%);
    z-index: 0;
    width: 812px;
    height: 100%;
    background: linear-gradient(124deg, #f7cf01 0%, #fec000 0%, #ffffff 100%);
    opacity: 0.3;
    filter: blur(50px);
`;

export default function MintAndStake() {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const { pathname } = useLocation();
    const handleFAQChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const isMint = useMemo(() => {
        return new RegExp('/mint&stake/mint').test(pathname);
    }, [pathname]);
    const isStake = useMemo(() => {
        return new RegExp('/mint&stake/stake').test(pathname);
    }, [pathname]);
    return (
        <Wrapper>
            <NavWrapper>
                <Nav
                    to="/mint&stake/mint"
                    className={(isActive) => (isActive ? 'active' : '')}
                >
                    Mint
                </Nav>
                <Nav
                    to="/mint&stake/stake"
                    className={(isActive) => (isActive ? 'active' : '')}
                >
                    Stake
                </Nav>
                <Nav
                    to="/mint&stake/rewards"
                    className={(isActive) => (isActive ? 'active' : '')}
                >
                    Rewards
                </Nav>
            </NavWrapper>
            <ChildWrapper>
                <Route path="/mint&stake/mint">
                    <Mint />
                </Route>
                <Route path="/mint&stake/stake">
                    <Stake />
                </Route>
                <Route path="/mint&stake/rewards">
                    <Rewards />
                </Route>
            </ChildWrapper>
            <FaqWrapper>
                <FaqBg />
                <AutoColumn justify="center">
                    <Title>FAQ</Title>
                </AutoColumn>
                {faqs.all.map((item, index) => (
                    <Accordion
                        key={`all${index}`}
                        expanded={expanded === `all${index}`}
                        onChange={handleFAQChange(`all${index}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <TYPE.main
                                fontSize={20}
                                fontWeight={600}
                                mobileFz={12}
                                wordBreak
                            >
                                {`${index + 1}. ${item.q}`}
                            </TYPE.main>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TYPE.main
                                fontSize={20}
                                fontWeight={600}
                                mobileFz={12}
                            >
                                {item.a}
                            </TYPE.main>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </FaqWrapper>
        </Wrapper>
    );
}
