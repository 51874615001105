import Row, { RowBetween } from 'components/Row';
import TooltipInfo from 'components/TooltipInfo';
import React, { useMemo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { TYPE } from 'theme';
import { AutoColumn } from 'components/Column';
import Button from 'components/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {
    discord,
    eMetisSymbol,
    enkiSymbol,
    medium,
    seMetisSymbol,
    telegram,
    twitter,
} from 'common-helpers/img';
import BannerBg from 'assets/images/home/img_top.png';
import BannerENKI from 'assets/images/home/img_enki.png';
import EtherumImg from 'assets/images/home/ic_ethereum.png';
import AndromedaImg from 'assets/images/home/ic_andromeda.png';
import SequencerBG from 'assets/images/home/img_bg_1.png';
import LearnMoreBG from 'assets/images/home/img_bg_2.png';
import { NavLink, useHistory } from 'react-router-dom';
import useEMetisInfo from 'hooks/useEMetisInfo';
import useSeMetisInfo from 'hooks/useSeMetisInfo';
import { useConstantToken } from 'hooks/useConstantToken';
import { useGetTokenPrice } from 'hooks/useTokenPrice';
import Numeral from 'numeral';
import useGetAvailableText from 'hooks/useAvailableText';
import useEnkiInfo from 'hooks/useEnkiInfo';
import { useGetGlobalProfit } from 'hooks/useGetRewards';
import useSequencerInfo from 'hooks/useSequencerInfo';

const GlobalStyle = createGlobalStyle`
    .mainView {
        padding-bottom: 0 !important;
    }
`;

const Wrapper = styled.div``;

const BannerWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    height: 624px;
    background: url(${BannerBg}) no-repeat;
    background-position: center;
    background-size: 100% 100%;
    position: relative;
    margin-top: -120px;
    img {
        width: 203px;
        height: 312px;
        position: absolute;
        left: 230px;
        top: 205px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100px;
        height: auto;
        left: 20px;
        bottom: 20px;
        right: unset;
        top: unset;
        `};
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 300px;
    background-size: cover;
    `};
`;

const BannerTextWrapper = styled.div`
    width: 620px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 120px;
    gap: 20px;
    div {
        text-align: right;
    }

    button {
        font-weight: bold;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 90%;
    margin-right: 20px;
    z-index: 1;
    margin-top: 70px;
    `};
`;
const Block = styled.div`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 64px 120px;
    background: linear-gradient(180deg, #fffdf6 0%, #fff6d4 100%);

    &.bg {
        background: #fffdf6;
    }

    &.white {
        background: #fff;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    `};
`;

const Overview = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1200px;
    flex-direction: row;
    height: 220px;
    background: linear-gradient(
        180deg,
        #fbecbf 0%,
        #ffffff 12%,
        #ffffff 90%,
        #fbecbf 100%
    );
    border-radius: 24px;
    overflow: hidden;
    flex-wrap: wrap;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 220px;
    width: 100%;
    flex-direction: column;
    `};
`;

const OverviewDetailWrapper = styled.div`
    width: 100%;
    height: 180px;
    background: #fff;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    `};
`;

const OverviewItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    height: 130px;
    width: 24%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 20px;
    gap: 10px;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
    padding: 10px;
    height: 100px;
    `};
`;

const RightBorder = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 43px;
    background-color: #ccc;
    transform: translateY(-50%);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    `};
`;

const SequenceWrapper = styled.div`
    width: 100%;
    height: 340px;
    background: url(${SequencerBG}) no-repeat;
    background-size: 100% 50%;
    background-position: top center;
    box-shadow: 0px 10px 10px 0px rgba(219, 154, 50, 0.1);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    gap: 10px;
    position: relative;
    margin-top: 60px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 440px;
    `};
`;

const SequencerLogoWrapper = styled.div`
    position: absolute;
    width: 160px;
    height: 160px;
    border-radius: 80px;
    left: 50%;
    top: 0;
    transform: translate(-50%, -60%);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;

    img {
        width: 155px;
        height: 155px;
        border-radius: 50%;
        overflow: hidden;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 100px;
      height: 100px;
      img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
    }
    `};
`;

const SequencerInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 222px;
    background: #ffffff;
    border-radius: 24px;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: center;
    align-items:center;
    height: 350px;
    `};
`;

const SequencerInfoItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 33%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 20px;
    gap: 20px;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const Title = styled.div`
    font-size: 34px;
    color: #0e0905;
    font-weight: bold;
    margin: 40px 0;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 24px;
        margin-top: 32px !important;
    `};
`;

const WhyENKIWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    gap: 40px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    `};
`;

const WhyENKIItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 32px;
    img {
        width: 120px;
        height: 120px;
    }
    background: linear-gradient(180deg, #ffffff 0%, #fffdf6 100%) #ffffff;
    border-radius: 24px;
    width: 580px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const EcoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: row;
    gap: 30px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    `};
`;

const EcoItem = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 32px;
    padding-bottom: 40px;
    .top {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 20px;
        flex-shrink: 0;
    }
    img {
        width: 80px;
        height: 80px;
    }
    background: linear-gradient(180deg, #ffffff 0%, #fffdf6 100%) #ffffff;
    box-shadow: 0px 10px 10px 0px rgba(219, 154, 50, 0.1);
    border-radius: 24px;
    width: 380px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const EcoButton = styled(NavLink)`
    color: #fdd436;
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    font-weight: bold;
    transform: translateX(-50%);
`;

const LearnMore = styled.div`
    width: 1200px;
    height: 280px;

    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    background: url(${LearnMoreBG}) no-repeat;
    background-position: center;
    background-size: 100%;

    button {
        width: 280px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    background-size: cover;
    height: 200px;
    flex-direction: column;
    gap: 20px;
    `};
`;

const Roadmap = styled.div`
    padding-top: 140px;
    .MuiStepper-root {
        position: relative;
        z-index: 0;
    }
    .MuiStepConnector-root {
        top: 6px;
        left: calc(-50%);
        right: calc(50%);
    }

    .MuiStepConnector-line {
        border-width: 12px;
        position: relative;
        z-index: -1;
        border-color: #fbecbf;
    }

    .MuiSvgIcon-root {
        color: #fbecbf !important;

        .MuiStepIcon-text {
            color: #000 !important;
        }

        &.Mui-active {
            color: #fdd436 !important;
        }
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    overflow-x: scroll;
    padding-top: 30px;
    `};
`;

const RoadmapItem = styled.div`
    width: 188px;
    height: 99px;
    background: #fbecbf;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
`;

const CommuintyWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 64px;
`;

const Forum = styled.div`
    width: 188px;
    height: 72px;
    background: #fff8e4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: row;

    border-radius: 8px;
    background: #fff8e4;

    img {
        width: 40px;
        height: 40px;
    }
`;

const MediaWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    flex-direction: row;
`;

const MediaImg = styled.img``;

const WorksImg = styled.img`
    width: 100%;
`;

const ViewOnSequencer = styled.div`
    position: absolute;
    right: 32px;
    top: 28px;
    height: 40px;
    background: #0e09052e;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 50%;
    transform: translateX(50%);
    top: 43px;
    height: 30px;
    `};
`;

export default function Home() {
    const history = useHistory();
    const { stakingApr } = useGetGlobalProfit();
    const { totalSupply: eMetisTotalSupply, totalStaked: totalEMetisStaked } =
        useEMetisInfo();
    const {
        tokenHolder,
        exchangeRate,
        totalSupply: seMetisTotalSupply,
    } = useSeMetisInfo();
    const { metis } = useConstantToken();
    const metisPrice = useGetTokenPrice(metis.address);
    const ava = useGetAvailableText();
    const { totalSupply: enkiTotalSupply, totalStaked: totalEnkiStaked } =
        useEnkiInfo();
    const { sequencerInfo } = useSequencerInfo();

    const overviewTotalValueLock = useMemo(() => {
        if (metisPrice) {
            return Numeral(
                totalEMetisStaked.multipliedBy(metisPrice).toNumber(),
            ).format('$ 0,0');
        } else {
            return '--';
        }
    }, [metisPrice, totalEMetisStaked]);
    return (
        <Wrapper className="container">
            <GlobalStyle />
            <BannerWrapper>
                <img src={BannerENKI} alt="" />
                <BannerTextWrapper>
                    <TYPE.main fontSize={48} mobileFz={24} fontWeight={600}>
                        Metis Sequencer Node Staking Derivative
                    </TYPE.main>
                    <TYPE.desc fontSize={18} mobileFz={14} color="#000">
                        A secure, simplified, and DeFi-focused platform for
                        earning interest on Metis.
                    </TYPE.desc>
                    <Button
                        onClick={() => {
                            history.push('/mint&stake/mint');
                        }}
                    >
                        Launch App
                    </Button>
                </BannerTextWrapper>
            </BannerWrapper>
            <Block>
                <Title>Overview</Title>
                <Overview>
                    <OverviewDetailWrapper>
                        <OverviewItem>
                            <Row justify="center">
                                <TYPE.main fontSize={14} mobileFz={12}>
                                    Total eMetis Minted
                                </TYPE.main>
                                <TooltipInfo text="The total amount of eMetis coming out through METIS minted with 1:1 ratio, the same amount of staking METIS" />
                            </Row>
                            <TYPE.main
                                fontSize={24}
                                fontWeight={600}
                                mobileFz={16}
                            >
                                {Numeral(eMetisTotalSupply).format('0,0')}{' '}
                                eMetis
                            </TYPE.main>
                            <RightBorder />
                        </OverviewItem>
                        <OverviewItem>
                            <Row justify="center">
                                <TYPE.main fontSize={14} mobileFz={12}>
                                    Total Value Locked
                                </TYPE.main>
                                <TooltipInfo text="The market value of eMetis locked" />
                            </Row>
                            <TYPE.main
                                fontSize={24}
                                fontWeight={600}
                                mobileFz={16}
                            >
                                {overviewTotalValueLock}
                            </TYPE.main>
                            {/* <TYPE.desc>
                                {Numeral(totalEMetisStaked).format('0,0')}{' '}
                                eMetis
                            </TYPE.desc> */}
                            <RightBorder />
                        </OverviewItem>
                        <OverviewItem>
                            <Row justify="center">
                                <TYPE.main fontSize={14} mobileFz={12}>
                                    Estimated seMetis APR
                                </TYPE.main>
                                <TooltipInfo text="Past 24 hrs total rewards amount * 90% * 365 / Total amount of eMetis staked into seMetis Vault * 100%" />
                            </Row>
                            <TYPE.main
                                fontSize={24}
                                fontWeight={600}
                                mobileFz={16}
                            >
                                {stakingApr
                                    ? Numeral(stakingApr).format('0.00 %')
                                    : '--'}
                            </TYPE.main>
                            <RightBorder />
                        </OverviewItem>
                        <OverviewItem>
                            <Row justify="center">
                                <TYPE.main fontSize={14} mobileFz={12}>
                                    seMetis Holder
                                </TYPE.main>
                                <TooltipInfo text="The total amount of seMetis Holder" />
                            </Row>
                            <TYPE.main
                                fontSize={24}
                                fontWeight={600}
                                mobileFz={16}
                            >
                                {tokenHolder || '--'}
                            </TYPE.main>
                        </OverviewItem>
                    </OverviewDetailWrapper>
                </Overview>
                <Title style={{ marginTop: '64px' }}>How ENKI works</Title>
                <WorksImg src={require('assets/images/home/img_works.png')} />
            </Block>
            <Block className="bg">
                <SequenceWrapper>
                    <TYPE.main
                        color="#fff"
                        fontSize={24}
                        mobileFz={16}
                        fontWeight={600}
                    >
                        ENKI Sequencer
                    </TYPE.main>
                    <SequencerInfoWrapper>
                        {/* <ViewOnSequencer>
                            <TYPE.main fontSize={14} color={'#fff'}>
                                View on Metis Sequencer
                            </TYPE.main>
                        </ViewOnSequencer> */}
                        <SequencerLogoWrapper>
                            <img src={enkiSymbol} alt="" />
                        </SequencerLogoWrapper>
                        <SequencerInfoItem>
                            <Row justify="center">
                                <TYPE.main fontSize={14} mobileFz={12}>
                                    L1 Locked-up
                                </TYPE.main>
                                <TooltipInfo text="The amount of Metis locked in layer1 currently" />
                            </Row>
                            <TYPE.main
                                fontSize={24}
                                mobileFz={16}
                                fontWeight={600}
                            >
                                {sequencerInfo
                                    ? Numeral(sequencerInfo.l1Staked).format(
                                          '0,0',
                                      )
                                    : '--'}{' '}
                                METIS
                            </TYPE.main>
                            <RightBorder />
                        </SequencerInfoItem>
                        <SequencerInfoItem>
                            <Row justify="center">
                                <TYPE.main fontSize={14} mobileFz={12}>
                                    Mining Reward
                                </TYPE.main>
                                <TooltipInfo text="The reward of sequencers in layer1" />
                            </Row>
                            <TYPE.main
                                fontSize={24}
                                mobileFz={16}
                                fontWeight={600}
                            >
                                {sequencerInfo
                                    ? Numeral(
                                          sequencerInfo.totalRewards,
                                      ).format('0,0')
                                    : '--'}{' '}
                                METIS
                            </TYPE.main>
                            <RightBorder />
                        </SequencerInfoItem>
                        <SequencerInfoItem>
                            <Row justify="center">
                                <TYPE.main fontSize={14} mobileFz={12}>
                                    Bridging to L1
                                </TYPE.main>
                                <TooltipInfo text="The total amount of METIS sent to layer1" />
                            </Row>
                            <TYPE.main
                                fontSize={24}
                                mobileFz={16}
                                fontWeight={600}
                            >
                                {sequencerInfo
                                    ? Numeral(sequencerInfo.syncingToL1).format(
                                          '0,0',
                                      )
                                    : '--'}{' '}
                                METIS
                            </TYPE.main>
                        </SequencerInfoItem>
                        <Row justify="center">
                            {ava(
                                <TYPE.main fontSize={24} fontWeight={600}>
                                    Last sync: 2 hours 20 minutes ago
                                </TYPE.main>,
                                <TYPE.desc fontSize={12} fontWeight={600}>
                                    Not available on Testnet(Sync time)
                                </TYPE.desc>,
                            )}
                        </Row>
                    </SequencerInfoWrapper>
                </SequenceWrapper>
            </Block>
            <Block>
                <Title>Why ENKI</Title>
                <WhyENKIWrapper>
                    <WhyENKIItem>
                        <img src={EtherumImg} alt="" />
                        <TYPE.main fontSize={18} mobileFz={16} fontWeight={600}>
                            Sequencers Mining on Ethereum
                        </TYPE.main>
                        <AutoColumn>
                            <TYPE.main fontSize={14}>
                                • Ordinary users can’t participate
                            </TYPE.main>
                            <TYPE.main fontSize={14}>
                                • Minimum of 2,000 METIS tokens required
                            </TYPE.main>{' '}
                            <TYPE.main fontSize={14}>
                                • Liquidity locked
                            </TYPE.main>
                            <TYPE.main fontSize={14}>
                                • Node server setup and management needed
                            </TYPE.main>
                        </AutoColumn>
                    </WhyENKIItem>
                    <WhyENKIItem>
                        <img src={AndromedaImg} alt="" />
                        <TYPE.main fontSize={18} mobileFz={16} fontWeight={600}>
                            ENKI Staking on Andromeda
                        </TYPE.main>
                        <AutoColumn>
                            <TYPE.main fontSize={14}>
                                • No threshold, all users can participate
                            </TYPE.main>
                            <TYPE.main fontSize={14}>
                                • No METIS amount limit
                            </TYPE.main>
                            <TYPE.main fontSize={14}>
                                • Liquidity can be freely used for trading,
                                lending, and other activities
                            </TYPE.main>
                            <TYPE.main fontSize={14}>
                                • No node server setup or management
                            </TYPE.main>
                        </AutoColumn>
                    </WhyENKIItem>
                </WhyENKIWrapper>
            </Block>
            <Block className="bg">
                <Title>ENKI Ecosystem</Title>
                <EcoWrapper>
                    <EcoItem>
                        <div className="top">
                            <img src={eMetisSymbol} alt="" />
                            <TYPE.main
                                fontSize={18}
                                fontWeight={600}
                                mobileFz={16}
                            >
                                eMetis
                            </TYPE.main>
                            <TYPE.main fontSize={14} textAlign="center">
                                This acts as a stablecoin, loosely pegged to
                                Metis. It leverages Metis Sequencer Node staking
                                and enhances the composability of the Metis
                                ecosystem.
                            </TYPE.main>
                        </div>

                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={14}>
                                    Total minted
                                </TYPE.main>
                                <TooltipInfo text="The total amount of eMetis coming out through Metis minted currently" />
                            </Row>
                            <TYPE.main fontSize={14} flexShrink={0}>
                                {Numeral(eMetisTotalSupply).format('0,0')}{' '}
                                eMetis
                            </TYPE.main>
                        </RowBetween>
                        <RowBetween>
                            <TYPE.main fontSize={14}>Price</TYPE.main>
                            <TYPE.main fontSize={14}>= 1 METIS</TYPE.main>
                        </RowBetween>
                        <EcoButton to="/mint&stake/mint">Mint now</EcoButton>
                    </EcoItem>
                    <EcoItem>
                        <div className="top">
                            <img src={seMetisSymbol} alt="" />
                            <TYPE.main
                                fontSize={18}
                                fontWeight={600}
                                mobileFz={16}
                            >
                                seMetis
                            </TYPE.main>
                            <TYPE.main fontSize={14} textAlign="center">
                                This is a version of eMetis that accrues staking
                                yield. The majority of the profit generated from
                                Metis sequencer nodes is distributed to seMetis
                                holders. By staking eMetis for seMetis, users
                                can earn staking yields, redeemable when
                                converting seMetis back to eMetis.
                            </TYPE.main>
                        </div>
                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={14}>
                                    Total minted
                                </TYPE.main>
                                <TooltipInfo text="The total amount of seMetis coming out through eMetis staked currently" />
                            </Row>
                            <TYPE.main fontSize={14} flexShrink={0}>
                                {Numeral(seMetisTotalSupply).format('0,0')}{' '}
                                seMetis
                            </TYPE.main>
                        </RowBetween>

                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={14}>
                                    Total Staked
                                </TYPE.main>
                                <TooltipInfo text="The total amount of eMetis staked in staking yield" />
                            </Row>
                            <TYPE.main fontSize={14} flexShrink={0}>
                                {Numeral(totalEMetisStaked).format('0,0')}{' '}
                                eMetis
                            </TYPE.main>
                        </RowBetween>
                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={14}>APR</TYPE.main>
                                <TooltipInfo text="eMetis’ staking yield" />
                            </Row>
                            {ava(
                                <TYPE.main fontSize={24} fontWeight={600}>
                                    --%
                                </TYPE.main>,
                                <TYPE.desc fontSize={12} fontWeight={600}>
                                    Not available on Testnet
                                </TYPE.desc>,
                            )}
                        </RowBetween>
                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={14}>
                                    eMetis per seMetis
                                </TYPE.main>
                                <TooltipInfo text="Exchange rates for eMetis and seMetis" />
                            </Row>
                            <TYPE.main fontSize={14} flexShrink={0}>
                                {exchangeRate
                                    ? (1 / exchangeRate).toFixed(3)
                                    : '--'}{' '}
                            </TYPE.main>
                        </RowBetween>
                        <EcoButton to="/mint&stake/stake">Stake now</EcoButton>
                    </EcoItem>
                    {/* <EcoItem>
                        <div className="top">
                            <img src={enkiSymbol} alt="" />
                            <TYPE.main
                                fontSize={18}
                                fontWeight={600}
                                mobileFz={16}
                            >
                                ENKI
                            </TYPE.main>
                            <TYPE.main fontSize={12} textAlign="center">
                                This component allows the exchange of Metis for
                                eMetis, boosts sequencer nodes when possible,
                                and mints new eMetis equivalent to the amount of
                                Metis sent.
                            </TYPE.main>
                        </div>

                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={14}>
                                    Total Suppply
                                </TYPE.main>
                                <TooltipInfo text="Market supply" />
                            </Row>
                            <TYPE.main fontSize={14} flexShrink={0}>
                                {enkiTotalSupply
                                    ? Numeral(enkiTotalSupply).format('0,0')
                                    : '--'}{' '}
                                ENKI
                            </TYPE.main>
                        </RowBetween>
                        <RowBetween>
                            <Row>
                                <TYPE.main fontSize={14}>
                                    Total Staked
                                </TYPE.main>
                                <TooltipInfo text="The total amount of ENKI staked in vesting yield" />
                            </Row>
                            <TYPE.main fontSize={14} flexShrink={0}>
                                {Numeral(totalEnkiStaked).format('0,0')} ENKI
                            </TYPE.main>
                        </RowBetween>
                        <RowBetween>
                            <TYPE.main fontSize={14}>Price</TYPE.main>
                            {ava(
                                <TYPE.main fontSize={14} fontWeight={600}>
                                    = $ 0.00
                                </TYPE.main>,
                                <TYPE.desc fontSize={12} fontWeight={600}>
                                    Not available on Testnet
                                </TYPE.desc>,
                            )}
                        </RowBetween>
                        <EcoButton to="/mint&stake/stake">Stake now</EcoButton>
                    </EcoItem> */}
                </EcoWrapper>
                <Title style={{ marginTop: '64px' }}>Learn about ENKI</Title>
                <LearnMore>
                    <TooltipInfo text="Not available on Testnet">
                        <Button type="outline">
                            <TYPE.main
                                color="#fff"
                                fontSize={18}
                                fontWeight={600}
                            >
                                Litepaper
                            </TYPE.main>
                        </Button>
                    </TooltipInfo>
                    <TooltipInfo text="Not available on Testnet">
                        <Button>Learn More</Button>
                    </TooltipInfo>
                </LearnMore>
                <Title style={{ marginTop: '64px' }}>Roadmap</Title>
                <Roadmap>
                    <Stepper activeStep={0} alternativeLabel>
                        <Step key={1}>
                            <StepLabel>
                                <AutoColumn gap="40px" justify="center">
                                    <TYPE.main fontSize={14} fontWeight={600}>
                                        Q3 2023
                                    </TYPE.main>
                                    <RoadmapItem>
                                        Development of the Proof of Concept.
                                    </RoadmapItem>
                                </AutoColumn>
                            </StepLabel>
                        </Step>
                        <Step key={2}>
                            <StepLabel>
                                <AutoColumn gap="40px" justify="center">
                                    <TYPE.main fontSize={14} fontWeight={600}>
                                        Q4 2023
                                    </TYPE.main>
                                    <RoadmapItem>
                                        Release of beta version on testnet and
                                        public testing.
                                    </RoadmapItem>
                                </AutoColumn>
                            </StepLabel>
                        </Step>
                        <Step key={3}>
                            <StepLabel>
                                <AutoColumn gap="40px" justify="center">
                                    <TYPE.main fontSize={14} fontWeight={600}>
                                        Q1 2024
                                    </TYPE.main>
                                    <RoadmapItem>
                                        Public release and subsequent product
                                        upgrades.
                                    </RoadmapItem>
                                </AutoColumn>
                            </StepLabel>
                        </Step>
                    </Stepper>
                </Roadmap>
            </Block>
        </Wrapper>
    );
}
