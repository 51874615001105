import { gql } from '@apollo/client';
import { getApolloClient } from './index';
import { Client } from './config';

export interface Deployed {
    l2Metis: string;
    eMetis: string;
    eMetisMinter: string;
    enki: string;
    vesting: string;
    rewardDispatcher: string;
    seMetis: string;
    networkName: string;
    config: string;
}

export function getContracts(): Promise<Deployed | undefined> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<{
                contracts: Deployed;
            }>({
                query: gql`
                    query contracts {
                        contracts {
                            l2Metis
                            eMetis
                            eMetisMinter
                            enki
                            vesting
                            rewardDispatcher
                            seMetis
                            networkName
                            config
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                resolve(res?.data?.contracts);
            })
            .catch((e: any) => {
                resolve(undefined);
            });
    });
}
