import React from 'react';
import styled from 'styled-components';
import Row, { RowBetween } from '../Row';
import {
    discord,
    logo,
    enkiSymbol,
    medium,
    telegram,
    twitter,
} from 'common-helpers/img';
import { AutoColumn } from 'components/Column';
import { Link, TYPE } from 'theme';
import TooltipInfo from 'components/TooltipInfo';
// import Modal from 'utils/components/modal';

const FooterWrapper = styled.div`
    width: 100%;
    padding: 36px 120px;
    background-color: #fff;

    a {
        text-decoration: none;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 40px 20px;
    padding-bottom: 60px;
    `};
`;
const Logo = styled.img`
    width: 120px;
    height: 40px;
`;

const MediaWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    flex-direction: row;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 20px;
    justify-content: flex-start;
    `};
`;

const MediaImg = styled.img``;

const Forum = styled.div`
    width: 188px;
    height: 72px;
    background: #fff8e4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: row;

    border-radius: 36px;
    background: #fff8e4;

    img {
        width: 40px;
        height: 40px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 48px;
    `};
`;
const MediaInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    `};
`;

export default function Footer() {
    return (
        <FooterWrapper>
            <MediaInfo>
                <Row>
                    <Logo src={logo} />
                </Row>
                <MediaWrapper>
                    <a
                        href="https://twitter.com/ENKIProtocol"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MediaImg src={twitter} />
                    </a>
                    <a
                        href="https://t.me/enkixyz"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MediaImg src={telegram} />
                    </a>
                    <a
                        href="https://medium.com/@ENKIProtocol"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MediaImg src={medium} />
                    </a>
                    {/* <a
                        href="https://medium.com/@ENKIProtocol"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MediaImg src={discord} />
                    </a> */}
                </MediaWrapper>
            </MediaInfo>
            <RowBetween marginTop={20}>
                <Row align="flex-start" gap="60px">
                    <AutoColumn gap="24px">
                        <TYPE.main fontSize={16} fontWeight={600}>
                            RESOURCE
                        </TYPE.main>
                        <TooltipInfo text="Not available on Testnet">
                            <TYPE.main fontSize={14}>Litepaper</TYPE.main>
                        </TooltipInfo>
                        <TooltipInfo text="Not available on Testnet">
                            <TYPE.main fontSize={14}>Privacy Policy</TYPE.main>
                        </TooltipInfo>
                        <TooltipInfo text="Not available on Testnet">
                            <TYPE.main fontSize={14}>User Terms</TYPE.main>
                        </TooltipInfo>
                    </AutoColumn>
                    <AutoColumn gap="24px">
                        <TYPE.main fontSize={16} fontWeight={600}>
                            DEVELOPERS
                        </TYPE.main>
                        {/* <Link href="/brand" inner>
                            <TYPE.main fontSize={14}>Mediakit</TYPE.main>
                        </Link> */}
                        <TooltipInfo text="Not available on Testnet">
                            <TYPE.main fontSize={14}>Github</TYPE.main>
                        </TooltipInfo>
                        <TooltipInfo text="Not available on Testnet">
                            <TYPE.main fontSize={14}>Audit</TYPE.main>
                        </TooltipInfo>
                    </AutoColumn>
                </Row>
                {/* <TooltipInfo text="Not available now">
                    <Forum>
                        <img src={enkiSymbol} alt="" />
                        <TYPE.main
                            fontSize={20}
                            color="#DBA45C"
                            fontWeight={600}
                            mobileFz={14}
                        >
                            Forum
                        </TYPE.main>
                    </Forum>
                </TooltipInfo> */}
            </RowBetween>
        </FooterWrapper>
    );
}
