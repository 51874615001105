import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Row from 'components/Row';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { useWeb3Modal, useWeb3ModalTheme } from '@web3modal/react';
import NuvoSVG from 'assets/images/nuvo.svg';
import WalletconnectSVG from 'assets/images/walletconnect.svg';
import Loading from 'components/Loading';
import { useConnect } from 'wagmi';
import { observer } from 'mobx-react';
import user from 'stores/user';
import { DefaultChainId } from 'constant';

const Content = styled.div`
    width: 460px;
    box-sizing: border-box;
    /* padding: 10px 30px; */

    .nextButton {
        width: 80%;
    }

    .walletsList {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 18px;
        /* margin-top: 30px; */

        .item {
            width: 100%;
            height: 66px;
            margin-top: 12px;
            background-color: #fff;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: ${({ theme }) => theme.c.t01};
            box-sizing: border-box;
            padding: 0 10px;

            .icon {
                width: 32px;
                height: 32px;
                margin-left: 10px;
                background-size: 100%;
            }

            &.polis {
                .icon {
                    background-image: url(${NuvoSVG});
                }
            }
            &.installMetaMask {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;

                .metaMask {
                    width: 32px;
                    height: 32px;
                    background-image: url(${require('assets/images/metamask-icon.png')});
                    background-size: 100% 100%;
                    background-position: center center;
                    margin: 8px 0;
                }
            }

            &.wallet-connect {
                .icon {
                    width: 32px;
                    height: 32px;
                    background: url(${WalletconnectSVG}) no-repeat;
                    background-size: 100%;
                    background-position: center center;
                }
            }
            &.metamask {
                .icon {
                    width: 32px;
                    height: 32px;
                    background: url(${require('assets/images/metamask-icon.png')})
                        no-repeat;
                    background-size: 100%;
                    background-position: center center;
                }
            }
            // &.walletConnect {
            //     background: url(../../assets/images/walletconnect.png) no-repeat;
            //     background-size: 208px 34px;
            //     background-position: center center;
            // }
        }
    }

    .warning {
        line-height: 1.7;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 100%;
        .walletsList {
            .item {
                width: 100%;
            }
        }
    `}
`;

interface Props {
    isOpen: boolean;
    onClose(): void;
}
function ConnectWallet({ isOpen, onClose }: Props) {
    const { setTheme } = useWeb3ModalTheme();
    const { isDark } = user;
    const [isOpenWeb3Modal, setIsOpenWeb3Modal] = useState(false);
    const { open } = useWeb3Modal();

    const { connect, connectors } = useConnect();

    const metaMaskNotInstall = useMemo(() => {
        return !connectors.find((item) => item.name === 'MetaMask');
    }, [connectors]);

    useEffect(() => {
        setTheme({
            themeMode: isDark ? 'dark' : 'light',
        });
    }, [isDark]);

    async function handleClick(name: string) {
        if (name === 'metamask') {
            const mmConnector = connectors.find(
                (item) => item.name === 'MetaMask',
            );
            if (mmConnector) {
                connect({
                    chainId: DefaultChainId,
                    connector: mmConnector,
                });
            } else {
                open();
            }
            onClose();
        } else if (name === 'walletconnect') {
            setIsOpenWeb3Modal(true);
            await open();
            setIsOpenWeb3Modal(false);
            onClose();
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            // customContentClass={style.modalContent}
        >
            <Content>
                <Row justify="center">
                    <TYPE.main fontSize={16} fontWeight="bold">
                        Connect your wallet
                    </TYPE.main>
                </Row>
                <div className={'walletsList'}>
                    {/* <div
                        className={cx('item', 'polis')}
                        onClick={() => {
                            handleClick('polis');
                        }}
                    >
                        <TYPE.main fontSize={14}>Connect by Nuvo</TYPE.main>
                        <div className={'icon'} />
                    </div> */}
                    <div
                        className={cx('item', 'metamask')}
                        onClick={() => {
                            if (metaMaskNotInstall) {
                                window.open('https://metamask.io/');
                            } else {
                                handleClick('metamask');
                            }
                        }}
                    >
                        <TYPE.main fontSize={14}>
                            {metaMaskNotInstall
                                ? 'Install Metamask'
                                : 'Connect by Metamask'}
                        </TYPE.main>
                        <div className="icon" />
                    </div>

                    <div
                        className={cx('item', 'wallet-connect')}
                        onClick={() => {
                            handleClick('walletconnect');
                        }}
                    >
                        {isOpenWeb3Modal ? (
                            <>
                                <TYPE.main fontSize={14}>Loading</TYPE.main>
                                <Loading style={{ color: '#000' }} size={20} />
                            </>
                        ) : (
                            <>
                                <TYPE.main fontSize={14}>
                                    Connect by WalletConnect
                                </TYPE.main>
                                <div className="icon" />
                            </>
                        )}
                    </div>
                </div>
            </Content>
        </Modal>
    );
}

export default observer(ConnectWallet);
