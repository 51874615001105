import {
    eMetisSymbol,
    enkiSymbol,
    metisSymbol,
    seMetisSymbol,
} from 'common-helpers/img';
import { ChainId } from 'constant';
import Deployed599 from './deployed-599.json';
import Deployed59902 from './deployed-59902.json';

export const metisConfig = {
    [ChainId.mainnet]: {
        name: 'Metis token',
        symbol: 'METIS',
        address: '',
        decimals: 18,
        img: metisSymbol,
    },
    [ChainId.testnet]: {
        name: 'Metis token',
        symbol: 'METIS',
        address: '',
        decimals: 18,
        img: metisSymbol,
    },
    [ChainId.testnetSepolia]: {
        name: 'Metis token',
        symbol: 'METIS',
        address: '',
        decimals: 18,
        img: metisSymbol,
    },
};

export const eMetisConfig = {
    [ChainId.mainnet]: {
        name: 'Enki Metis token',
        symbol: 'eMetis',
        address: '',
        decimals: 18,
        img: eMetisSymbol,
    },
    [ChainId.testnet]: {
        name: 'Enki Metis token',
        symbol: 'eMetis',
        address: '',
        decimals: 18,
        img: eMetisSymbol,
    },
    [ChainId.testnetSepolia]: {
        name: 'Enki Metis token',
        symbol: 'eMetis',
        address: '',
        decimals: 18,
        img: eMetisSymbol,
    },
};

export const seMetisConfig = {
    [ChainId.mainnet]: {
        name: 'Staked Enki Metis token',
        symbol: 'seMetis',
        address: '',
        decimals: 18,
        img: seMetisSymbol,
    },
    [ChainId.testnet]: {
        name: 'Staked Enki Metis token',
        symbol: 'seMetis',
        address: '',
        decimals: 18,
        img: seMetisSymbol,
    },
    [ChainId.testnetSepolia]: {
        name: 'Staked Enki Metis token',
        symbol: 'seMetis',
        address: '',
        decimals: 18,
        img: seMetisSymbol,
    },
};

export const enkiConfig = {
    [ChainId.mainnet]: {
        name: 'Enki token',
        symbol: 'ENKI',
        address: '',
        decimals: 18,
        img: enkiSymbol,
    },
    [ChainId.testnet]: {
        name: 'Enki token',
        symbol: 'ENKI',
        address: '',
        decimals: 18,
        img: enkiSymbol,
    },
    [ChainId.testnetSepolia]: {
        name: 'Enki token',
        symbol: 'ENKI',
        address: '',
        decimals: 18,
        img: enkiSymbol,
    },
};
