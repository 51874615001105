import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Link, TYPE } from 'theme';
import TranscationModal from 'components/TranscationModal';
import ListItem from 'components/TranscationModal/listItem';
import Checkbox from 'components/CheckBox';
import Row, { RowBetween } from 'components/Row';
import Button from 'components/Button';
import { AutoColumn } from 'components/Column';
import { eMetisSymbol } from 'common-helpers/img';
import TooltipInfo from 'components/TooltipInfo';
import BigNumber from 'bignumber.js';
import TokenInput from 'components/TokenInput';
import AddTokenToWallet from 'components/AddTokenToWallet';
import { useConstantToken } from 'hooks/useConstantToken';
import { useEMetisMinter, useSeMetisVault } from 'hooks/useContract';
import useSend from 'hooks/useSend';
import useTokenApprove from 'hooks/useTokenApprove';
import user from 'stores/user';
import { observer } from 'mobx-react-lite';
import Modal from 'components/Modal';
import useTokenBalance from 'hooks/useTokenBalance';
import { useGetEstimatedGas } from 'hooks/useEstimateGas';
import useEMetisInfo from 'hooks/useEMetisInfo';
import Numeral from 'numeral';
import { useGetEMetisMetisLPConfig } from 'hooks/useGetResources';
import useExploreLink from 'hooks/useExploreLink';
import { isMobile } from 'react-device-detect';
import useSeMetisInfo from 'hooks/useSeMetisInfo';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 64px;
    width: 100%;
    flex-direction: column;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 32px;
    `};
`;

const Overview = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background: #fff;
    border-radius: 24px;
    flex-wrap: wrap;
    overflow: hidden;
    width: 1200px;
    min-height: 220px;
    box-sizing: border-box;
    padding: 10px;
    flex-wrap: wrap;
    box-shadow: 0px 10px 10px 0px rgba(219, 154, 50, 0.1);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 260px;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    `};
`;

const OverviewItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    height: 140px;
    width: 24%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 20px;
    gap: 10px;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
    gap: 5px;
    height: 120px;
    `};
`;

const Title = styled.div`
    font-size: 34px;
    color: #0e0905;
    font-weight: bold;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 24px;
    `};
`;

const Desc = styled.div`
    font-size: 24px;
    color: #0e0905;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 16px;
    `};
`;

const MainWrapper = styled.div`
    width: 1200px;
    background: linear-gradient(180deg, #ffeaa8 0%, #ffffff 60%);
    border-radius: 24px;
    padding: 0 280px;
    box-sizing: border-box;
    gap: 48px;
    padding-bottom: 48px;
    margin-top: 90px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 0 10px;
    padding-bottom: 20px;
    `};
`;

const MainHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transform: translateY(-50%);
    padding-bottom: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      justify-content: center;
      gap: 20px;
    `};
    .info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 32px;
        position: relative;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        gap: 12px;
        `};
        .imgContainer {
            width: 120px;
            height: 120px;
            border-radius: 60px;
            overflow: hidden;
            background: #fffae5;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 80px;
                height: 80px;
            }

            ${({ theme }) => theme.mediaWidth.upToSmall`
             width: 80px;
            height: 80px;
            border-radius: 40px;

            img {
                width: 60px;
                height: 60px;
            }
            `};
        }

        .tokenTitle {
            position: absolute;
            bottom: -50px;
            left: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            ${({ theme }) => theme.mediaWidth.upToSmall`
            left: 15px;
            `};
        }
    }
`;

const InfoWrapper = styled(AutoColumn)`
    margin-top: 50px;
    width: 100%;
    border-top: 1px solid #e8dbd8;
    padding-top: 56px;
    width: 100%;
    gap: 24px;
`;

const InfoImg = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 16px;
`;

const ButtonGroup = styled(Row)`
    gap: 40px;
    margin-top: 80px;

    button {
        width: 200px;
    }
`;

const RedeptionContent = styled.div`
    width: 540px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const RightBorder = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 43px;
    background-color: #8b4c3b;
    transform: translateY(-50%);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    `};
`;

const EMetisBalanceWrapper = styled.div`
    width: 630px;
    height: 64px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: column;
    justify-content:center;
    `};
`;

function Mint() {
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [inputValue, setInputValue] = useState(new BigNumber(0));
    const [isMintAndStake, setIsMintAndStake] = useState(false);
    const [isOpenTransModal, setIsOpenTransModal] = useState(false);
    const { metis, eMetis } = useConstantToken();
    const eMetisMinterContract = useEMetisMinter();
    const seMetisVaultContract = useSeMetisVault();
    const { totalSupply: eMetisTotalSupply } = useEMetisInfo();
    const { lps } = useGetEMetisMetisLPConfig();
    const { getAddressUrl } = useExploreLink();
    const { exchangeRate } = useSeMetisInfo();
    const [isRedemptionModalOpen, setIsRedemptionModalOpen] = useState(false);
    const { balance } = useTokenBalance(eMetis.address, user.address);
    const { approved: approvedEMetisMinter, approve: approveEMetisMinter } =
        useTokenApprove(metis, eMetisMinterContract?.address || '', inputValue);

    const { approved: approvedSeMetisVault, approve: approveSeMetisVault } =
        useTokenApprove(metis, seMetisVaultContract?.address || '', inputValue);

    const send = useSend();

    const [inputValid, setInputValid] = useState(false);

    const canClickButton = useMemo(() => {
        return inputValue.isGreaterThan(0) && inputValid;
    }, [inputValue, inputValid]);

    function closeTransModal() {
        setIsOpenTransModal(false);
    }

    function onTransSuccess() {
        closeTransModal();
        setInputValue(new BigNumber(0));
    }

    const estimatedGas = useGetEstimatedGas({
        contract: eMetisMinterContract,
        method: 'mint',
        params: [user.address, inputValue.shiftedBy(metis.decimals).toFixed(0)],
        show: inputValue.isGreaterThan(0),
        fetch: approvedEMetisMinter && inputValue.isGreaterThan(0),
    });

    async function mint() {
        if (!eMetisMinterContract) return;

        await send({
            contract: eMetisMinterContract,
            method: 'mint',
            params: [
                user.address,
                inputValue.shiftedBy(metis.decimals).toFixed(0),
            ],
            onSuccess: onTransSuccess,
        });
    }

    async function mintAndStake() {
        if (!eMetisMinterContract) return;

        await send({
            contract: eMetisMinterContract,
            method: 'mintAndDeposit',
            params: [
                user.address,
                inputValue.shiftedBy(metis.decimals).toFixed(0),
            ],
            onSuccess: onTransSuccess,
        });
    }

    return (
        <Wrapper>
            <Overview>
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            Total eMetis minted
                        </TYPE.main>
                    </Row>
                    <TYPE.main fontSize={24} fontWeight={600} mobileFz={16}>
                        {Numeral(eMetisTotalSupply).format('0,0')} eMetis
                    </TYPE.main>
                    <RightBorder />
                </OverviewItem>
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            eMetis/METIS LP
                        </TYPE.main>
                    </Row>
                    {lps ? (
                        lps.map((item, index) => (
                            <Link href={(item as any).website} key={index}>
                                <TYPE.main
                                    fontSize={16}
                                    fontWeight={600}
                                    mobileFz={14}
                                    textAlign={'center'}
                                >
                                    {(item as any).name}
                                </TYPE.main>
                            </Link>
                        ))
                    ) : (
                        <TYPE.main
                            fontSize={16}
                            fontWeight={600}
                            mobileFz={14}
                            textAlign={'center'}
                        >
                            --
                        </TYPE.main>
                    )}
                </OverviewItem>
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            Sequencer INFO
                        </TYPE.main>
                    </Row>
                    <TooltipInfo text="Coming soon">
                        <TYPE.main fontSize={16} fontWeight={600} mobileFz={14}>
                            Enki Dashboard
                        </TYPE.main>
                    </TooltipInfo>
                    <Link href="#" disableText="Not available on Testnet">
                        <TYPE.main
                            fontSize={16}
                            fontWeight={600}
                            mobileFz={14}
                            textAlign={'center'}
                        >
                            View on Metis sequencer
                        </TYPE.main>
                    </Link>

                    <RightBorder />
                </OverviewItem>
                <OverviewItem>
                    <Row justify="center">
                        <TYPE.main fontSize={14} mobileFz={12}>
                            Contracts
                        </TYPE.main>
                    </Row>
                    <Link href={getAddressUrl(eMetis.address)}>
                        <TYPE.main
                            fontSize={16}
                            fontWeight={600}
                            mobileFz={14}
                            textAlign={'center'}
                        >
                            eMetis
                        </TYPE.main>
                    </Link>
                    <Link
                        href={getAddressUrl(
                            eMetisMinterContract?.address || '',
                        )}
                    >
                        <TYPE.main
                            fontSize={16}
                            fontWeight={600}
                            mobileFz={14}
                            textAlign={'center'}
                        >
                            eMetis Minter
                        </TYPE.main>
                    </Link>
                </OverviewItem>
            </Overview>
            <AutoColumn justify="center" gap="20px">
                <Title>Mint eMetis</Title>

                <Desc>Submit METIS and mint eMetis</Desc>
                {user.connected && (
                    <EMetisBalanceWrapper>
                        <TYPE.main mobileFz={14}>Your eMetis Balance</TYPE.main>
                        <TYPE.main fontSize={20} mobileFz={16} fontWeight={600}>
                            {balance.toFixed(3)} eMetis
                        </TYPE.main>
                    </EMetisBalanceWrapper>
                )}
            </AutoColumn>
            <MainWrapper>
                <MainHeader>
                    <div className="info">
                        <div className="imgContainer">
                            <img src={metis.img} />
                        </div>
                        <div className="tokenTitle">
                            <TYPE.main
                                fontSize={18}
                                fontWeight={600}
                                marginRight="8px"
                            >
                                {metis.symbol}
                            </TYPE.main>
                        </div>
                    </div>
                    <TooltipInfo text="It is not yet possible to exchange eMetis into METIS currently. Please go to the exchange to exchange it by yourself.">
                        <div className="info">
                            <div className="imgContainer">
                                <img
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        if (isMobile) return;
                                        setIsRedemptionModalOpen(true);
                                    }}
                                    src={require('assets/images/mintAndStake/ic_switch_single.png')}
                                />
                            </div>
                        </div>
                    </TooltipInfo>

                    <div className="info">
                        <div className="imgContainer">
                            <img src={eMetis.img} />
                        </div>
                        <div className="tokenTitle">
                            <TYPE.main
                                fontSize={18}
                                fontWeight={600}
                                marginRight="8px"
                            >
                                {eMetis.symbol}
                            </TYPE.main>
                            <AddTokenToWallet token={eMetis} />
                        </div>
                    </div>
                </MainHeader>
                <TokenInput
                    value={inputValue}
                    onChange={setInputValue}
                    tokenImage={metis.img}
                    inputPercent={[50]}
                    token={metis}
                    onInputValid={setInputValid}
                />
                <InfoWrapper>
                    <RowBetween>
                        <TYPE.main fontSize={14} fontWeight={600}>
                            You Will Receive
                        </TYPE.main>
                        <Row style={{ width: 'auto' }}>
                            <InfoImg src={eMetisSymbol} />
                            <TYPE.main fontSize={14}>
                                {inputValue.toFixed()} eMetis
                            </TYPE.main>
                        </Row>
                    </RowBetween>
                    <RowBetween>
                        <TYPE.main fontSize={14}>Exchange Rate</TYPE.main>
                        <TYPE.main fontSize={14}>1 METIS = 1 eMetis</TYPE.main>
                    </RowBetween>
                    <RowBetween>
                        <TYPE.main fontSize={14}>Estimated Gas Fee</TYPE.main>
                        <TYPE.main fontSize={14}>
                            {approvedEMetisMinter
                                ? `${estimatedGas} METIS`
                                : 'Approved first'}
                        </TYPE.main>
                    </RowBetween>
                </InfoWrapper>

                <ButtonGroup justify="center">
                    {!approvedEMetisMinter ? (
                        <Button
                            type="outline"
                            // disabled={!canClickButton}
                            // onClick={() => {
                            //     setIsMintAndStake(false);
                            //     setIsOpenTransModal(true);
                            // }}
                            disabled={!canClickButton}
                            onClick={approveEMetisMinter}
                        >
                            Approve
                        </Button>
                    ) : (
                        <Button
                            type="outline"
                            // disabled={!canClickButton}
                            // onClick={() => {
                            //     setIsMintAndStake(false);
                            //     setIsOpenTransModal(true);
                            // }}
                            disabled={!canClickButton}
                            onClick={mint}
                        >
                            Mint
                        </Button>
                    )}
                    <Button
                        disabled={!canClickButton}
                        onClick={() => {
                            setIsMintAndStake(true);
                            setIsOpenTransModal(true);
                        }}
                    >
                        Mint & Stake
                    </Button>
                </ButtonGroup>
            </MainWrapper>
            <TranscationModal
                isOpen={isOpenTransModal}
                onClose={closeTransModal}
                title={isMintAndStake ? 'Mint&Stake' : 'Mint'}
                amountDesc="Amount to stake"
                amount={inputValue.toFixed()}
                token={metis}
            >
                <ListItem type="init" text="Initiate minting" />
                <ListItem
                    type="eMetis"
                    text={`receive ${inputValue.toFixed()} eMetis`}
                />
                <ListItem type="init" text="Initiate staking" />
                <ListItem
                    type="seMetis"
                    text={`receive ${inputValue
                        .multipliedBy(exchangeRate)
                        .toFixed()} seMetis`}
                />
                <ListItem type="calender" text="Wait 7 days" />
                <ListItem type="send" text="Start earning" />
                <Checkbox
                    checked={checked1}
                    onCheck={setChecked1}
                    text="I understand that due to the 7-day bridging process from L2 to L1, staking rewards will start after 7 days."
                />
                <Checkbox
                    checked={checked2}
                    onCheck={setChecked2}
                    text="I understand that 70% of the eMetis rewards earned from eMetis staking will be converted upon redeeming seMetis. The remaining 30% of the rewards will be vested and can be released by staking ENKI within 365 days."
                />
                <Row justify="center" gap="40px" marginTop="20px">
                    {!approvedEMetisMinter && (
                        <Button
                            disabled={!checked1 || !checked2}
                            onClick={approveEMetisMinter}
                        >
                            Approve
                        </Button>
                    )}
                    {!isMintAndStake ? (
                        <Button
                            disabled={
                                !approvedEMetisMinter || !checked1 || !checked2
                            }
                            onClick={mint}
                        >
                            Mint
                        </Button>
                    ) : (
                        <Button
                            disabled={
                                !approvedEMetisMinter || !checked1 || !checked2
                            }
                            onClick={mintAndStake}
                        >
                            Mint & Stake
                        </Button>
                    )}
                </Row>
                {!approvedSeMetisVault && (
                    <TYPE.desc>
                        *To preserve your control and agency, you need to
                        customize how many tokens you allow dapp to access in
                        your wallet.
                    </TYPE.desc>
                )}
            </TranscationModal>
            <Modal
                isOpen={isRedemptionModalOpen}
                onClose={() => {
                    setIsRedemptionModalOpen(false);
                }}
            >
                <RedeptionContent>
                    <AutoColumn justify="center" gap="32px">
                        <TYPE.main fontSize={20} mobileFz={16} fontWeight={600}>
                            Redemption Coming Soon!
                        </TYPE.main>
                        <TYPE.main
                            fontSize={16}
                            mobileFz={14}
                            textAlign="center"
                        >
                            {`We're working on it! The redemption feature for SEtoken
                        isn't available just yet. In the meantime, feel free to
                        redeem Mtoken on the secondary market.`}
                        </TYPE.main>
                        <Link href="#">Swap eMetis/Metis on Netswap</Link>
                        <Button
                            onClick={() => {
                                setIsRedemptionModalOpen(false);
                            }}
                        >
                            Understood
                        </Button>
                    </AutoColumn>
                </RedeptionContent>
            </Modal>
        </Wrapper>
    );
}

export default observer(Mint);
