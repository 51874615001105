import { Profit, getUserProfit } from 'gql/profits';
import { useEffect, useMemo, useState } from 'react';
import user from 'stores/user';
import { useConstantToken } from './useConstantToken';
import BigNumber from 'bignumber.js';

export default function useUserProfit() {
    const [profit, setProfit] = useState<Profit>();
    const { eMetis } = useConstantToken();

    async function getUserProfitFunc() {
        try {
            const res = await getUserProfit(user.chainId, user.address);

            setProfit(res);
        } catch (e) {
            console.error('getUserProfitFunc error', user.address);
            console.error(e);
        }
    }

    useEffect(() => {
        if (user.address && user.chainId) {
            getUserProfitFunc();
        }
    }, [user.address, user.chainId]);

    return {
        profit: useMemo(() => {
            return {
                apr: profit
                    ? new BigNumber(profit.apr)
                          .shiftedBy(-eMetis.decimals)
                          .toNumber()
                    : 0,
                value: profit
                    ? new BigNumber(profit.value)
                          .shiftedBy(-eMetis.decimals)
                          .toNumber()
                    : 0,
            };
        }, [profit, eMetis]),
        getUserProfit: getUserProfitFunc,
    };
}
