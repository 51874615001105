import { eMetisSymbol } from 'common-helpers/img';
import { AutoColumn } from 'components/Column';
import LineChart from 'components/LineChart';
import Row, { RowBetween } from 'components/Row';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link, TYPE } from 'theme';
import Balance from '../Balance';
import useEMetisInfo from 'hooks/useEMetisInfo';
import { useGetTokenPrice } from 'hooks/useTokenPrice';
import Numeral from 'numeral';
import { useConstantToken } from 'hooks/useConstantToken';
import TooltipInfo from 'components/TooltipInfo';
import { useGetGlobalProfit } from 'hooks/useGetRewards';
import useExploreLink from 'hooks/useExploreLink';
import { useEMetisMinter } from 'hooks/useContract';

const Wrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 48px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 20px);
    gap: 20px;
    `};
`;

const InfoContainer = styled.div`
    background: #ffffff;
    border-radius: 24px;
    box-sizing: border-box;
    padding: 32px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 10px;
    `};
    .token {
        width: 32px;
        height: 32px;
    }
`;

const InfosWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    gap: 48px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 20px;
    flex-direction: column-reverse;
    `};
`;

const InfoItemBlockWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    .item {
        width: 252px;
        height: 120px;
        border-radius: 6px;
        border: 1px solid #e8dbd8;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 10px;
    .item {
        width: calc(50% - 10px)
    }
    `};
`;

const InfoItem = styled.div`
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    flex-grow: 1;
`;

const ChartWrapper = styled.div`
    width: 550px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 20px);
    `};
`;

const ProgressBar = styled.div`
    position: relative;
    width: 528px;

    height: 48px;
    background: #fff6d7;
    border-radius: 4px;
    z-index: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-right: 8px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const Progress = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    background: #fdd436;
    border-radius: 4px;
    box-sizing: border-box;
    color: #000;
    display: flex;
    font-size: 14px;
    align-items: center;
    padding-left: 8px;
    justify-content: flex-start;
    z-index: 1;
`;

const FullBorderItem = styled.div`
    width: 100%;
    height: 120px;
    border-radius: 6px;
    border: 1px solid #e8dbd8;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    padding: 20px;
    box-sizing: border-box;
`;

export default function Overview() {
    const { getAddressUrl } = useExploreLink();
    const eMetisMinter = useEMetisMinter();
    const { totalSupply: eMetisTotalSupply, totalStaked: totalEMetisStaked } =
        useEMetisInfo();

    const { stakingApr } = useGetGlobalProfit();
    const { metis, eMetis, seMetis } = useConstantToken();
    const metisPrice = useGetTokenPrice(metis.address);
    const eMetisPrice = useGetTokenPrice(eMetis.address);
    const overviewTotalValueLock = useMemo(() => {
        if (metisPrice) {
            return Numeral(
                totalEMetisStaked.multipliedBy(metisPrice).toNumber(),
            ).format('$ 0,0');
        } else {
            return '--';
        }
    }, [metisPrice, totalEMetisStaked]);

    return (
        <Wrapper>
            <InfoContainer>
                <Row>
                    <img src={eMetisSymbol} className="token" alt="" />
                    <TYPE.main fontSize={20} marginLeft={10} fontWeight={600}>
                        eMetis Overview
                    </TYPE.main>
                </Row>
                <InfosWrapper style={{ marginTop: '24px' }}>
                    <InfoItem>
                        <TYPE.main fontSize={20} fontWeight={600}>
                            APR & TVL
                        </TYPE.main>

                        <InfoItemBlockWrapper>
                            <div className="item">
                                <TYPE.main
                                    fontSize={20}
                                    fontWeight={600}
                                    mobileFz={14}
                                >
                                    {overviewTotalValueLock}
                                </TYPE.main>
                                <Row justify="center">
                                    <TYPE.main fontSize={16} mobileFz={14}>
                                        Total Value Locked
                                    </TYPE.main>
                                    <TooltipInfo text="The market value of eMetis locked" />
                                </Row>
                            </div>
                            <div className="item">
                                <TYPE.main
                                    fontSize={20}
                                    fontWeight={600}
                                    mobileFz={14}
                                >
                                    {Numeral(eMetisTotalSupply).format('0,0')}{' '}
                                </TYPE.main>
                                <TYPE.main fontSize={16} mobileFz={14}>
                                    eMetis Total Supply
                                </TYPE.main>
                            </div>
                            <div className="item">
                                <TYPE.main
                                    fontSize={20}
                                    fontWeight={600}
                                    mobileFz={14}
                                >
                                    {stakingApr
                                        ? Numeral(stakingApr).format('0.00 %')
                                        : '--'}
                                </TYPE.main>

                                <TYPE.main fontSize={16} mobileFz={14}>
                                    seMetis APR
                                </TYPE.main>
                            </div>
                            <div className="item">
                                <TYPE.main
                                    fontSize={20}
                                    fontWeight={600}
                                    mobileFz={14}
                                >
                                    {eMetisPrice
                                        ? Numeral(eMetisPrice).format(
                                              '$ 0.[00]',
                                          )
                                        : '--'}
                                </TYPE.main>
                                <TYPE.main fontSize={16} mobileFz={14}>
                                    eMetis Price
                                </TYPE.main>
                            </div>
                            <div className="item">
                                <TYPE.main
                                    fontSize={20}
                                    fontWeight={600}
                                    mobileFz={14}
                                >
                                    {stakingApr
                                        ? Numeral(stakingApr).format('0.00 %')
                                        : '--'}
                                </TYPE.main>
                                <TYPE.main fontSize={16} mobileFz={14}>
                                    Current APR
                                </TYPE.main>
                            </div>
                        </InfoItemBlockWrapper>
                        <Row>
                            <TYPE.main fontSize={20} fontWeight={600}>
                                seMetis reward cycles
                            </TYPE.main>
                        </Row>
                        <RowBetween align="flex-start">
                            <AutoColumn gap="14px">
                                <TYPE.main fontSize={16} fontWeight={600}>
                                    Links
                                </TYPE.main>
                                <Link href="/mint&stake/mint">
                                    <TYPE.main wordBreak fontSize={14}>
                                        Mint eMetis
                                    </TYPE.main>
                                </Link>
                                {/* <Link href="#">
                                    <TYPE.main wordBreak fontSize={14}>
                                        eMetis/Metis pool on Netswap
                                    </TYPE.main>
                                </Link> */}
                                {/* <Link href="#">
                                    <TYPE.main wordBreak fontSize={14}>
                                        Docs
                                    </TYPE.main>
                                </Link> */}
                            </AutoColumn>
                            <AutoColumn gap="14px">
                                <TYPE.main fontSize={16} fontWeight={600}>
                                    Contract
                                </TYPE.main>
                                <Link href={getAddressUrl(eMetis.address)}>
                                    <TYPE.main wordBreak fontSize={14}>
                                        eMetis
                                    </TYPE.main>
                                </Link>

                                <Link href={getAddressUrl(seMetis.address)}>
                                    <TYPE.main wordBreak fontSize={14}>
                                        seMetis
                                    </TYPE.main>
                                </Link>
                                <Link
                                    href={getAddressUrl(
                                        eMetisMinter?.address || '',
                                    )}
                                >
                                    <TYPE.main wordBreak fontSize={14}>
                                        eMetis Minter
                                    </TYPE.main>
                                </Link>
                                {/* <Link
                                    href={getAddressUrl(
                                        eMetisMinter?.address || '',
                                    )}
                                >
                                    <TYPE.main wordBreak fontSize={14}>
                                        eMetis Treasury
                                    </TYPE.main>
                                </Link> */}
                            </AutoColumn>
                        </RowBetween>
                    </InfoItem>
                    <InfoItem style={{ flexShrink: 0 }}>
                        <ChartWrapper>
                            <LineChart />
                        </ChartWrapper>
                    </InfoItem>
                </InfosWrapper>
            </InfoContainer>
            <Balance />
            <InfoContainer>
                <InfosWrapper>
                    <InfoItem>
                        <TYPE.main fontSize={20} fontWeight={600}>
                            Sequencers
                        </TYPE.main>

                        <InfoItemBlockWrapper>
                            <div className="item">
                                <TYPE.main
                                    fontSize={20}
                                    fontWeight={600}
                                    mobileFz={14}
                                >
                                    2
                                </TYPE.main>
                                <TYPE.main fontSize={16} mobileFz={14}>
                                    Active Sequencers
                                </TYPE.main>
                            </div>
                            <div className="item">
                                <TYPE.main
                                    fontSize={20}
                                    fontWeight={600}
                                    mobileFz={14}
                                >
                                    22.92 METIS/day
                                </TYPE.main>
                                <TYPE.main fontSize={16} mobileFz={14}>
                                    Average Income
                                </TYPE.main>
                            </div>
                            <div className="item">
                                <TYPE.main
                                    fontSize={20}
                                    fontWeight={600}
                                    mobileFz={14}
                                >
                                    3,549,667,149 Metis
                                </TYPE.main>
                                <TYPE.desc fontSize={16} mobileFz={14}>
                                    $100.000,000,000
                                </TYPE.desc>
                                <TYPE.main fontSize={16}>TVL</TYPE.main>
                            </div>
                            <div className="item">
                                <TYPE.main
                                    fontSize={20}
                                    fontWeight={600}
                                    mobileFz={14}
                                >
                                    3,549,667,149 Metis
                                </TYPE.main>
                                <TYPE.desc fontSize={16} mobileFz={14}>
                                    $100.000,000,000
                                </TYPE.desc>
                                <TYPE.main
                                    fontSize={16}
                                    mobileFz={14}
                                    textAlign="center"
                                >
                                    Total Rewards Distributed
                                </TYPE.main>
                            </div>
                            <div className="item">
                                <TYPE.main
                                    fontSize={20}
                                    fontWeight={600}
                                    mobileFz={14}
                                >
                                    21.03%
                                </TYPE.main>
                                <TYPE.main fontSize={16} mobileFz={14}>
                                    Current APR
                                </TYPE.main>
                            </div>
                        </InfoItemBlockWrapper>
                        <Row>
                            <TYPE.main
                                fontSize={20}
                                fontWeight={600}
                                mobileFz={14}
                            >
                                Links
                            </TYPE.main>
                        </Row>
                        <Row>
                            <Link href="#">
                                <TYPE.main fontSize={16} mobileFz={14}>
                                    eMetis/Metis pool on Netswap
                                </TYPE.main>
                            </Link>
                        </Row>
                    </InfoItem>
                    <InfoItem style={{ flexShrink: 0 }}>
                        <ChartWrapper>123</ChartWrapper>
                    </InfoItem>
                </InfosWrapper>
            </InfoContainer>
        </Wrapper>
    );
}
