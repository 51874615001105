import { gql } from '@apollo/client';
import { getApolloClient } from './index';
import moment from 'moment';
import { Client } from './config';
import { ChainId } from 'constant';

export interface Profit {
    start: number;
    end: number;
    value: string;
    apr: string;
}

export interface GraphReturnData {
    dailyProfit: Profit[];
}

export function getRewardsHistory({
    start,
    end,
    user,
}: {
    start?: number;
    end?: number;
    user: string;
}): Promise<Profit[] | undefined> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<GraphReturnData>({
                query: gql`
                    query dailyProfit($start: Int, $end: Int, $user: String!) {
                        dailyProfit(start: $start, end: $end, user: $user) {
                            start
                            end
                            value
                            apr
                        }
                    }
                `,
                variables: {
                    start,
                    end,
                    user,
                },
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                const { data } = res;
                if (data?.dailyProfit) {
                    resolve(data?.dailyProfit);
                    resolve(undefined);
                }
            })
            .catch((e: any) => {
                resolve(undefined);
            });
    });
}

export function getProfit(
    start: number,
    end: number,
    user: string,
): Promise<Profit | undefined> {
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<{
                profit: Profit;
            }>({
                query: gql`
                    query profit($start: Int, $end: Int, $user: String!) {
                        profit(start: $start, end: $end, user: $user) {
                            start
                            end
                            value
                            apr
                        }
                    }
                `,
                variables: {
                    start,
                    end,
                    user,
                },
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                const { data } = res;
                if (data?.profit) {
                    resolve(data?.profit);
                    resolve(undefined);
                }
            })
            .catch((e: any) => {
                resolve(undefined);
            });
    });
}

export async function getFiveMonthProfit(
    user: string,
): Promise<(Profit | undefined)[]> {
    const ps = [];

    for (let i = 4; i >= 0; i--) {
        const now = moment();
        const month = [
            Math.floor(
                now.subtract(i, 'months').startOf('month').valueOf() / 1000,
            ),
            Math.floor(
                now.subtract(i, 'months').endOf('month').valueOf() / 1000,
            ),
        ];

        ps.push(getProfit(month[0], month[1], user));
    }

    const res = await Promise.all(ps);
    return res;
}

export function getGlobalProfit(): Promise<Profit | undefined> {
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<{
                globalProfit: Profit;
            }>({
                query: gql`
                    query globalProfit {
                        globalProfit {
                            start
                            end
                            value
                            apr
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                const { data } = res;
                if (data?.globalProfit) {
                    resolve(data?.globalProfit);
                    resolve(undefined);
                }
            })
            .catch((e: any) => {
                resolve(undefined);
            });
    });
}

export function getUserProfit(
    chainId: ChainId,
    user: string,
): Promise<Profit | undefined> {
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph, chainId)
            .query<{
                profit: Profit;
            }>({
                query: gql`
                    query profit($user: String!) {
                        profit(user: $user) {
                            value
                            apr
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                    user,
                },
            })
            .then((res) => {
                const { data } = res;
                if (data?.profit) {
                    resolve(data?.profit);
                    resolve(undefined);
                }
            })
            .catch((e: any) => {
                resolve(undefined);
            });
    });
}
